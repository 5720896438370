import React, { useCallback, useEffect } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isUndefined } from 'lodash';
import { Loader } from '@src/components/Loader';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { Results } from './components/Results';
import fields from './includes/fields.json';

/**
 * EidSearch
 *
 * Find additional information about a specific EID.
 *
 * @returns {Element}
 * @constructor
 */
export const EidSearch = () => {
  const { currentRoute } = useRoutesContext();
  const router = useRouter();
  const defaultValues = {
    eid: '',
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form?.formState;

  const { useApiQuery, queryClient } = useApi();

  const [GetRiskRecord, { data: riskRecord, isLoading, isFetching, isFetched, refetch }] = useApiQuery(
    'GetRiskRecord',
    {
      excludedHttpCodes: [404],
    }
  );

  /**
   * If the EID is passed in the URL, search for the EID.
   */
  useEffect(() => {
    if (currentRoute?.params?.eid) {
      form.setValue('eid', currentRoute?.params?.eid);
      GetRiskRecord({ eid: currentRoute?.params?.eid });
    } else {
      form.setValue('eid', '');
      queryClient.removeQueries('GetRiskRecord');
    }
  }, [currentRoute?.params?.eid]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @type {(function(*): void)|*}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      form.validate(
        `eid`,
        () => {},
        () => {}
      );

      if (!isSubmitting && isValid) {
        router?.navigate(`/analysis/eid-search/${submittedValues?.eid}`);
        form.setValue('eid', '');
      }
    },
    [isSubmitting, isValid]
  );

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/EidSearch.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col span={{ xs: '100%' }}>
              <Heading offset={0}>EID Search</Heading>
            </Grid.Col>
            <Grid.Col id="searchContainer" span={{ xs: '25%' }}>
              <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
                <TextInput
                  {...fields?.eid}
                  onClickSearch={() => {
                    handleSubmit(form?.getValues());
                  }}
                  onChange={() => {
                    form.validate(
                      `eid`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </FormProvider>
            </Grid.Col>
          </Grid>

          <Grid css={{ marginTop: 'var(--abyss-space-lg)' }}>
            {isLoading || isFetching ? (
              <Grid.Col span={{ xs: '100%' }}>
                <Loader verticalAlignment="top" />
              </Grid.Col>
            ) : (
              <Grid.Col span={{ xs: '100%' }}>
                {isFetched && (
                  <React.Fragment>
                    {isUndefined(riskRecord) ? (
                      <Grid.Col span={{ xs: '100%' }}>
                        <Alert title="EID Not Found" variant="info" />
                      </Grid.Col>
                    ) : (
                      <Results riskRecord={riskRecord} refetchRiskRecord={refetch} />
                    )}
                  </React.Fragment>
                )}
              </Grid.Col>
            )}
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * EntranceCriteria
 *
 * Provides the user with a screen to specify a list of filters to find risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EntranceCriteria = (props) => {
  const { assets, currentStep, form, isLoading } = props;

  const actionPaths = assets?.ListActionPaths?.data || [];
  const actionStatuses = assets?.ListActionStatuses?.data || [];
  const tags = assets?.ListTags?.data || [];

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/EntranceCriteria/EntranceCriteria.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col
              css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
              span={{
                xs: '100%',
              }}
            >
              <Heading offset={1}>{currentStep?.label}</Heading>
              <p>{currentStep?.description}</p>
            </Grid.Col>

            <Grid.Col id="filtersContainer" span={{ xs: '100%' }}>
              <Filters
                actionPaths={actionPaths}
                actionStatuses={actionStatuses}
                form={form}
                isLoading={isLoading}
                tags={tags}
              />
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

EntranceCriteria.propTypes = {
  assets: PropTypes.shape({
    ListActionPaths: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListActionStatuses: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};

EntranceCriteria.defaultProps = {
  assets: {},
  currentStep: {},
  form: {},
  isLoading: false,
};

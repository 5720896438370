import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isNil, isUndefined } from 'lodash';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';
import { Wizard } from '@src/routes/private/ActionPaths/components/Wizard';

/**
 * Edit
 *
 * Presents the user with a step-by-step (wizard) experience to modify an existing action path.
 *
 * @returns {Element}
 * @constructor
 */
export const Edit = () => {
  const [isManual, setIsManual] = useState(false);

  const { currentRoute } = useRoutesContext();
  const actionPathId = currentRoute?.params?.id;
  const actionPathStatus = currentRoute?.params?.status;
  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();
  const { toast } = useToast();

  const { useApiQuery } = useApi();

  const [GetActionPath, { data: actionPath, isLoading, isFetching }] = useApiQuery('GetActionPath');

  /**
   * Redirect the user back to the list screen if action path status is "ACTIVE" or "INACTIVE"
   */
  useEffect(() => {
    if (['ACTIVE', 'INACTIVE'].includes(String(actionPathStatus).toUpperCase())) {
      router?.navigate('/action-paths');
      toast.show({
        id: 'action-path-status-error',
        title: 'Invalid Status',
        message: (
          <React.Fragment>
            Not allowed to edit action paths with a status of <code>active</code> or <code>inactive</code>.
          </React.Fragment>
        ),
        variant: 'error',
      });
    }
  }, [actionPathStatus]);

  /**
   * Fetch the action path if it hasn't been loaded yet.
   */
  useEffect(() => {
    if (!isNil(actionPathId) && isUndefined(actionPath) && !isLoading && !isFetching) {
      GetActionPath({ actionPathId });
    }
  }, [actionPathId, actionPath, isLoading, isFetching]);

  useEffect(() => {
    if (!isUndefined(actionPath?.manualAssociation)) {
      setIsManual(actionPath?.manualAssociation);
    }
  }, [actionPath]);

  /**
   * Handle redirects based on the current step number and manual association.
   */
  useEffect(() => {
    if (isUndefined(routeParams?.step) && !isUndefined(path) && !path?.includes('step/1')) {
      router?.navigate(`${path}/step/1`);
    }
  }, [path, routeParams, isManual]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Edit/Edit.jsx">
      <Visibility>
        <Grid>
          <Grid.Col>
            <Heading offset={0}>{isManual ? 'Edit Manual Action Path' : 'Edit Action Path'}</Heading>
          </Grid.Col>
        </Grid>
        <Wizard mode="edit" actionPath={actionPath} isManual={isManual} />
      </Visibility>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Badge } from '@abyss/web/ui/Badge';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isArray, isUndefined, merge } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { RiskCodesTooltip, SourcesTooltip } from '@src/tooltips';
import { Table as TableComponent } from '@src/components/Table-query';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { uniq } from 'lodash/array';
import { Visibility } from '@src/components/Visibility';
import { TotalRecords } from './components/Header/components/TotalRecords';
import { Header } from './components/Header';
import { ExportStatus } from './components/Header/components/ExportStatus';
import { ExpansionRow } from './components/ExpansionRow';
import { EntranceCriteria } from './components/Header/components/EntranceCriteria';
import configuration from './includes/configuration.json';

/**
 * Table: RiskRecords
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    allowExport,
    currentStep,
    error,
    exportDetails,
    exportRefetch,
    form,
    isLoading,
    pollingRate,
    requestArgs,
    requestFunction,
    requestKey,
    riskCodes,
    riskRecordCount,
    rows,
    setPollingRate,
    showHeader,
    totalPages,
    totalRecords,
  } = props;

  /**
   * renderCellEID
   *
   * renders a link to open in a remote system (HCM - Health Care Matrix)
   *
   * @param cell
   * @returns {JSX.Element}
   */
  const renderCellEID = ({ cell }) => {
    return <Link href={`/analysis/eid-search/${cell.value}/`}>{cell.value}</Link>;
  };

  /**
   * renderCellRiskScore
   *
   * @TODO - Needs description.
   *
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellRiskScore = ({ cell }) => {
    const data = cell?.value?.split('-');

    const letter = data.shift().toUpperCase();

    const codeDescriptions = [];

    data.forEach((codeNumber) => {
      const match = riskCodes.find((riskCode) => {
        return riskCode?.codeId === String(codeNumber);
      });

      if (!isUndefined(match) && !codeDescriptions.includes(match)) {
        codeDescriptions.push(match);
      }
    });

    if (letter === 'R') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="error" outline>
            <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'Y') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="warning" outline>
            <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'G') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="success" outline>
            {cell?.value}
          </Badge>
        </Layout.Group>
      );
    }

    return cell.value;
  };

  /**
   * renderCellTrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellTrustedSources = ({ row }) => {
    const sources = [];

    row?.original?.ireRiskRecord?.trustedRecordSources?.forEach((trustedSource) => {
      if (!sources.includes(trustedSource?.source)) {
        sources.push(trustedSource?.source);
      }
    });

    return (
      <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
        <span className="truncate">{sources.join(', ')}</span>
      </Tooltip>
    );
  };

  /**
   * renderCellUntrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellUntrustedSources = ({ row }) => {
    if (isArray(row?.original?.ireRiskRecord?.remediationFindings)) {
      let sources = [];

      row?.original?.ireRiskRecord?.remediationFindings.forEach((attribute) => {
        if (isArray(attribute.untrustedRecordSources)) {
          attribute.untrustedRecordSources.forEach((untrustedSource) => {
            if (!sources.includes(untrustedSource.source)) {
              sources.push(untrustedSource.source);
            }
          });
        }
      });

      sources = uniq(sources);

      return (
        <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }
    return '';
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'EID') {
        column.Cell = renderCellEID;
      }

      if (column.Header === 'Risk Score') {
        column.Cell = renderCellRiskScore;
      }

      if (column.Header === 'Trusted Sources') {
        column.Cell = renderCellTrustedSources;
      }

      if (column.Header === 'Untrusted Sources') {
        column.Cell = renderCellUntrustedSources;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/tables/RiskRecords/Table.jsx">
      <Visibility>
        <Grid>
          {isUndefined(showHeader) || (!isUndefined(showHeader) && showHeader !== false) ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Flex direction="column" alignContent="flex-start">
                <Flex direction="row" alignItems="center">
                  <Heading offset={1}>{currentStep?.label}</Heading>
                </Flex>
                <p>{currentStep?.description}</p>
                <Layout.Group space={themeConfiguration?.theme?.space?.sm}>
                  <TotalRecords riskRecordCount={riskRecordCount} />
                  <Divider orientation="vertical" width={1} height={24} />
                  {allowExport === true && (
                    <React.Fragment>
                      <ExportStatus exportDetails={exportDetails} />
                      <Divider orientation="vertical" width={1} height={24} />
                    </React.Fragment>
                  )}
                  <EntranceCriteria form={form} />
                </Layout.Group>
              </Flex>
            </Grid.Col>
          ) : (
            <React.Fragment />
          )}
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <TableComponent
              {...{
                allowExport,
                currentStep,
                error,
                exportDetails,
                form,
                isLoading,
                pollingRate,
                requestArgs,
                requestFunction,
                requestKey,
                riskCodes,
                riskRecordCount,
                rows,
                setPollingRate,
                totalPages,
                totalRecords,
                columns,
                configuration: merge({}, configuration, {
                  renderSubComponent: ({ original }) => {
                    return <ExpansionRow row={original} />;
                  },
                }),
                headerLeft: (
                  <React.Fragment>
                    {allowExport === true && (
                      <Header
                        form={form}
                        exportDetails={exportDetails}
                        pollingRate={pollingRate}
                        setPollingRate={setPollingRate}
                        riskRecordCount={riskRecordCount}
                        allowExport={allowExport}
                        exportRefetch={exportRefetch}
                      />
                    )}
                  </React.Fragment>
                ),
              }}
            />
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  allowExport: PropTypes.bool,
  currentStep: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.bool,
  exportDetails: PropTypes.shape({
    exportStatus: PropTypes.string,
    exportType: PropTypes.string,
    exportUrl: PropTypes.string,
    exportUuid: PropTypes.string,
  }),
  exportRefetch: PropTypes.func,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  pollingRate: PropTypes.number,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeName: PropTypes.string,
      codeDescription: PropTypes.string,
    })
  ),
  riskRecordCount: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  setPollingRate: PropTypes.func,
  showHeader: PropTypes.bool,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  allowExport: false,
  currentStep: {
    description: '',
    label: '',
  },
  error: false,
  exportDetails: {
    exportStatus: '',
    exportType: '',
    exportUrl: '',
    exportUuid: '',
  },
  exportRefetch: () => {},
  form: {
    getFieldsValue: () => {},
    setFieldsValue: () => {},
  },
  isLoading: false,
  pollingRate: 0,
  requestArgs: {
    page: 0,
    size: 0,
    sort: '',
  },
  requestFunction: () => {},
  requestKey: 'table',
  riskCodes: [],
  riskRecordCount: 0,
  rows: [],
  setPollingRate: () => {},
  showHeader: true,
  totalPages: 0,
  totalRecords: 0,
};

import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { RiskCodesTooltip } from '@src/tooltips';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Visibility } from '@src/components/Visibility';

/**
 * RiskCode
 *
 * Displays a color coded risk code with a tooltip that displays the risk code description.
 *
 * @param props
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const RiskCode = (props) => {
  const { riskCode: theRiskCode, riskCodes } = props;

  let letter;
  const codeDescriptions = [];

  if (!isEmpty(riskCodes)) {
    const codes = theRiskCode?.split('-');
    letter = codes.shift().toUpperCase();

    codes.forEach((codeNumber) => {
      const match = riskCodes.find((riskCode) => {
        return riskCode?.codeId === String(codeNumber);
      });

      if (!isUndefined(match) && !codeDescriptions.includes(match)) {
        codeDescriptions.push(match);
      }
    });
  }

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/components/RiskCode/RiskCode.jsx">
      <Visibility>
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          {letter === 'R' && (
            <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.error1}>
              <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
                <div>{theRiskCode}</div>
              </Tooltip>
            </Text>
          )}

          {letter === 'Y' && (
            <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.warning1}>
              <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
                <div>{theRiskCode}</div>
              </Tooltip>
            </Text>
          )}

          {letter === 'G' && (
            <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.success1}>
              {theRiskCode}
            </Text>
          )}
        </Layout.Group>
      </Visibility>
    </ErrorHandler>
  );
};

RiskCode.propTypes = {
  riskCode: PropTypes.string,
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
};

RiskCode.defaultProps = {
  riskCode: '',
  riskCodes: [],
};

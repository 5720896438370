import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@abyss/web/ui/Box';
import { Chart } from '@src/components/Chart';
import { chartTypes } from '@src/components/Chart/includes/chartTypes';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { RiskRecords as RiskRecordsTable } from '@src/tables';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Styles } from './includes/styles';

/**
 * Widget: AggregationView
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AggregationView = (props) => {
  const { aggregation, assets, error, isLoading, requestArgs, requestFunction, requestKey, view } = props;

  const [currentChartType, setCurrentChartType] = useState(aggregation?.researchAggregation?.defaultChartType);
  const [data, setData] = useState(aggregation?.aggregationResults);

  let widgetMenuOptions = [];

  if (!view?.id?.includes('risk-records')) {
    widgetMenuOptions = [
      {
        title: 'View as...',
        label: 'View as...',
        onChange: setCurrentChartType,
        value: currentChartType,
        radios: aggregation?.researchAggregation?.chartTypes.map((chartType) => {
          return {
            label: chartTypes?.[chartType]?.label,
            value: chartType,
          };
        }),
      },
    ];
  }

  /**
   * Custom sorting based on view.
   */
  useEffect(() => {
    let theData = aggregation?.aggregationResults;

    if (['last-reopened-date', 'last-streamed-date'].includes(view?.id)) {
      const order = ['1 week', '1 week - 1 month', '1 month - 3 months', '3 months - 9 months', '> 9 months'];

      theData = sortBy(aggregation?.aggregationResults, (item) => {
        return order.indexOf(String(item.name).toLowerCase());
      });
    }

    if (data !== theData) {
      setData(theData);
    }
  }, [view?.id, aggregation?.aggregationResults]);

  /**
   * tableColumns
   *
   * Map the table columns based on the aggregation data.
   *
   * @type {*[]}
   */
  const tableConfiguration = useMemo(() => {
    const theConfiguration = {
      initialColumns: [],
      initialState: {
        sortBy: [
          {
            id: 'value',
            desc: true,
          },
        ],
      },
    };

    if (!isEmpty(aggregation?.researchAggregation?.dataTableColumnHeaders)) {
      Object.keys(aggregation?.researchAggregation?.dataTableColumnHeaders).forEach((accessor) => {
        theConfiguration.initialColumns.push({
          Header: aggregation?.researchAggregation?.dataTableColumnHeaders?.[accessor],
          accessor,
          /* eslint-disable react/prop-types */
          Cell: ({ cell }) => {
            // Formats number to display with commas.
            if (accessor === 'value') {
              return <React.Fragment>{Number(cell?.value).toLocaleString('en-US')}</React.Fragment>;
            }

            // Formats a number to display as a decimal.
            if (accessor === 'perc') {
              return <React.Fragment>{cell?.value}%</React.Fragment>;
            }

            return cell?.value;
          },
        });
      });
    }

    return theConfiguration;
  }, [aggregation?.researchAggregation?.dataTableColumnHeaders]);

  return (
    <ErrorHandler location="src/widgets/AggregationView/AggregationView.jsx">
      <Visibility>
        <Styles css={view?.id === 'risk-records' && { width: '100%' }}>
          <Box key={view?.id} height="" color="">
            <Widget title={view?.name || ''} description={view?.description || ''} menu={widgetMenuOptions || []}>
              {view?.id === 'risk-records' ? (
                <RiskRecordsTable
                  allowExport={false}
                  error={error}
                  isLoading={isLoading}
                  requestArgs={requestArgs}
                  requestFunction={requestFunction}
                  requestKey={requestKey}
                  riskCodes={assets?.ListRiskCodes?.data}
                  rows={aggregation?.content || []}
                  showHeader={false}
                  totalPages={aggregation?.totalPages || 1}
                  totalRecords={aggregation?.totalElements || 0}
                />
              ) : (
                <Chart type={currentChartType} data={data} tableConfiguration={tableConfiguration} legendLimit={15} />
              )}
            </Widget>
          </Box>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

AggregationView.propTypes = {
  aggregation: PropTypes.shape({
    aggregationResults: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        perc: PropTypes.number,
      })
    ),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        perc: PropTypes.number,
      })
    ),
    researchAggregation: PropTypes.shape({
      chartTypes: PropTypes.arrayOf(PropTypes.string),
      dataTableColumnHeaders: PropTypes.shape({
        accessor: PropTypes.string,
      }),
      defaultChartType: PropTypes.string,
    }),
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  assets: PropTypes.shape({
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          description: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string,
          riskLevel: PropTypes.number,
        })
      ),
    }),
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  view: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

AggregationView.defaultProps = {
  aggregation: {},
  assets: {},
  error: {},
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  view: {},
};

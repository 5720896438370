import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-descriptors-sub-text': {
    visibility: 'hidden',
    display: 'none',
  },
  '.abyss-data-table-root': {
    marginTop: 'var(--abyss-space-sm)',
  },
  td: {
    padding:
      'calc(var(--abyss-space-lg) - 5px) var(--abyss-space-md) calc(var(--abyss-space-lg) + 5px) var(--abyss-space-md)',
  },
  '#removeRow': {
    color: 'var(--abyss-colors-error1) !important',
    fontWeight: 'bold !important',
    '.abyss-icon': {
      marginTop: 'var(--abyss-space-xs)',
      marginRight: 'var(--abyss-space-xs)',
      color: 'var(--abyss-colors-error1) !important',
    },
    'span.abyss-icon-symbol:first-of-type': {
      display: 'none',
      visibility: 'hidden',
    },
    'span.abyss-icon-symbol:last-of-type': {
      display: 'inline-block',
      visibility: 'visible',
    },
    '&:hover': {
      'span.abyss-icon-symbol:first-of-type': {
        color: 'var(--abyss-colors-error1)',
        display: 'inline-block',
        visibility: 'visible',
      },
      'span.abyss-icon-symbol:last-of-type': {
        display: 'none',
        visibility: 'hidden',
      },
    },
  },
});

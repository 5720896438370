import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';

/**
 * AttachActionPaths
 *
 * Displays a modal to attach action paths to the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AttachActionPaths = (props) => {
  const { eid, isOpen, setIsOpen, refetchRiskRecord, recordActionPaths } = props;

  const { useApiQuery, useApiMutation, clearApiCache } = useApi();

  const [ListActionPaths, { data: actionPaths, isLoading, isFetching, refetch: refetchActionPaths }] =
    useApiQuery('ListActionPaths');
  const [RiskRecordActionPathAssociation] = useApiMutation('RiskRecordActionPathAssociation', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    actionPaths: [],
  };

  const form = useForm({ defaultValues });
  const { toast } = useToast();

  /**
   * Retrieve the list of action paths.
   */
  useEffect(() => {
    if (isUndefined(actionPaths)) {
      ListActionPaths({
        statusList: ['ACTIVE'],
        manualAssociation: 'true',
      });
    }
  }, [actionPaths]);

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    try {
      const toastId = `actionPath-association-${eid}`;
      toast.show({
        id: `${toastId}-info`,
        title: 'Attaching Action Paths to EID',
        message: 'Attaching Action Paths to EID...',
        isLoading: true,
        ariaLoadingLabel: 'Attaching Action Paths to EID',
        variant: 'info',
        autoClose: false,
      });

      const payload = {
        eids: [eid],
        actionPaths: submittedValues?.actionPaths,
      };

      await RiskRecordActionPathAssociation(payload, {
        onSuccess: () => {
          setIsOpen(false);
          clearApiCache(['GetRiskRecord', 'ListActionPaths']);
          refetchRiskRecord();
          refetchActionPaths();
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: 'Attached Action Paths to EID',
            message: 'Acton Paths have been successfully attached.',
            variant: 'success',
          });
          form?.setValue('actionPaths', []);
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: 'Attaching Action Paths Failed',
            message: 'Unable to attach Action Paths.',
            variant: 'error',
          });
          setIsOpen(false);
          form?.setValue('actionPaths', []);
        },
      });
    } catch (error) {
      console.error('Error in handleSubmit', error);
    }
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/components/AttachActionPaths/AttachActionPaths.jsx">
      <Visibility>
        <Modal
          title="Attach Action Paths"
          isOpen={isOpen}
          onClose={() => {
            return setIsOpen(false);
          }}
        >
          <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
            <Modal.Section>
              <Grid>
                {isLoading || isFetching || isUndefined(actionPaths) ? (
                  <Grid.Col
                    span={{
                      xs: '100%',
                    }}
                  >
                    <Loader />
                  </Grid.Col>
                ) : (
                  <Grid.Col
                    span={{
                      xs: '50%',
                    }}
                  >
                    <SelectInputMulti
                      label="Action Paths"
                      placeholder="Select Action Paths --"
                      model="actionPaths"
                      isSearchable
                      options={actionPaths?.content
                        ?.filter((actionPath) => {
                          return !recordActionPaths?.find((recordActionPath) => {
                            return recordActionPath?.actionPathId === actionPath?.id;
                          });
                        })
                        ?.map((actionPath) => {
                          return {
                            label: actionPath?.name,
                            value: actionPath?.id,
                          };
                        })}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </Modal.Section>
            <Modal.Section>
              <Divider height={1} />
              <Layout.Group alignLayout="right">
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="solid" type="submit" isDisabled={!form?.formState?.isDirty}>
                  Attach
                </Button>
              </Layout.Group>
            </Modal.Section>
          </FormProvider>
        </Modal>
      </Visibility>
    </ErrorHandler>
  );
};

AttachActionPaths.propTypes = {
  eid: PropTypes.string,
  isOpen: PropTypes.bool,
  refetchRiskRecord: PropTypes.func,
  recordActionPaths: PropTypes.arrayOf(
    PropTypes.shape({
      actionPathId: PropTypes.string,
      actionPathName: PropTypes.string,
    })
  ),
  setIsOpen: PropTypes.func,
};

AttachActionPaths.defaultProps = {
  eid: '',
  isOpen: false,
  recordActionPaths: [],
  setIsOpen: () => {},
  refetchRiskRecord: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';

/**
 * Remediation
 *
 * Displays remediation information about the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediationEvents = (props) => {
  const { remediationEvents } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/RemediationEvents/RemediationEvents.jsx">
      <Visibility>
        <Widget
          title="Remediation Events"
          description="Summary of key remediaiton events for EID in Rem Hub"
          collapsible
          collapsed
        >
          {isEmpty(remediationEvents) ? (
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
              <Alert title="There is no remediation data affiliated with this risk record." variant="info" />
            </Grid.Col>
          ) : (
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <Table rows={remediationEvents} />
              </Grid.Col>
            </Grid>
          )}
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

RemediationEvents.propTypes = {
  remediationEvents: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      activeActionPath: PropTypes.string,
      remediationMethods: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

RemediationEvents.defaultProps = {
  remediationEvents: [],
};

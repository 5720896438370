import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#filtersContainer': {
    marginTop: 'calc(calc(var(--abyss-space-xl) + var(--abyss-space-sm)) * -1)',
  },
});

export const AddNewFilterButton = styled('button', {
  width: '100%',
  padding: '$md 0',
  color: '$interactive1',
  fontWeight: '$bold',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$gray3',
  },
});

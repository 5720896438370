import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty } from 'lodash';
import { isValidStep } from '@src/routes/private/ActionPaths/components/Wizard/includes/functions';
import { Layout } from '@abyss/web/ui/Layout';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Visibility } from '@src/components/Visibility';

/**
 * Footer
 *
 * Presents the user with a floating navigation bar to toggle forwards/backwards between each step at the bottom of the
 * wizard/stepper experience.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Footer = (props) => {
  const { currentStep, firstStep, lastStep, nextStep, previousStep, ref, form, handleSubmit, isManual } = props;

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;

  const validName = !!isEmpty(form?.formState?.errors?.name);
  const isCurrentStepValid = isValidStep(currentStep, validName, form?.formState?.errors, isManual);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/components/Footer/Footer.jsx">
      <Visibility>
        <FloatingSection
          containerRef={ref}
          position="bottom"
          space="1"
          css={{
            'abyss-floating-section-root': {
              padding: `${themeConfiguration?.theme?.space?.xl}px 0px !important`,
              bottom: '0px',
              boxShadow: 'none !important',
              zIndex: 999,
            },
            boxShadow: 'none !important',
          }}
        >
          <Grid
            css={{
              margin: '0px !important',
              padding: `${themeConfiguration?.theme?.space?.xl}px 0px !important`,
            }}
          >
            <Grid.Col
              span={{
                xs: '50%',
                md: '50%',
                lg: '50%',
              }}
              css={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Layout.Group alignLayout="left">
                {currentStep?.order === firstStep?.order ? (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={async () => {
                      await handleSubmit();
                      router?.navigate('/action-paths');
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={async () => {
                      await handleSubmit();
                      router?.navigate(`${path?.slice(0, path?.length - 1)}${previousStep?.order}`);
                    }}
                    before={<IconSymbol icon="chevron_left" />}
                  >
                    {previousStep?.label || ''}
                  </Button>
                )}
              </Layout.Group>
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '50%',
                md: '50%',
                lg: '50%',
              }}
              css={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Layout.Group alignLayout="right">
                {currentStep?.order === lastStep?.order ? (
                  <Button
                    variant="solid"
                    disabled={!isCurrentStepValid}
                    type="button"
                    after={<IconSymbol css={{ color: '#fff' }} icon="keyboard_double_arrow_right" />}
                    onClick={async () => {
                      await handleSubmit();
                      router?.navigate(`/action-paths?highlight=${form?.getValues('id')}`);
                    }}
                  >
                    Save Action Path
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    disabled={!isCurrentStepValid}
                    type="button"
                    onClick={async () => {
                      if (isCurrentStepValid) {
                        await handleSubmit();
                        router?.navigate(`${path?.slice(0, path?.length - 1)}${nextStep?.order}`);
                      }
                    }}
                    after={<IconSymbol css={{ color: '#fff' }} icon="chevron_right" />}
                  >
                    {nextStep?.label || ''}
                  </Button>
                )}
              </Layout.Group>
            </Grid.Col>
          </Grid>
        </FloatingSection>
      </Visibility>
    </ErrorHandler>
  );
};

Footer.propTypes = {
  currentStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  firstStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  lastStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  nextStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  previousStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  ref: PropTypes.shape({
    current: PropTypes.shape({
      scrollIntoView: PropTypes.func,
    }),
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    trigger: PropTypes.func,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    register: PropTypes.func,
    getValues: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  isManual: PropTypes.bool,
};

Footer.defaultProps = {
  currentStep: {},
  firstStep: {},
  lastStep: {},
  nextStep: {},
  previousStep: {},
  ref: {},
  form: {},
  handleSubmit: () => {},
  isManual: false,
};

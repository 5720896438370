import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * Filters
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { actionPaths, actionStatuses, form, isLoading, tags } = props;
  const { currentRoute } = useRoutesContext();

  const { fields, append, remove, replace } = useFormFieldArray({
    control: form.control,
    name: 'filters',
  });

  /**
   * handleReset
   *
   * Resets the user specified filters from the data table.
   *
   * @returns {Promise<void>}
   */
  const handleReset = async () => {
    await form.setValue('filters', []);
    replace([]);
  };

  return (
    <ErrorHandler location="src/components/Filters/Filters.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col span={{ xs: '100%' }}>
              <Layout.Group alignLayout="right">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  variant="custom"
                  id="addField"
                  onClick={() => {
                    append({ field: '', condition: '', value: '' });
                  }}
                >
                  <Flex alignItems="center">
                    <div>
                      <IconSymbol icon="add_circle" variant="filled" />
                      <IconSymbol icon="add_circle" variant="outline" />
                    </div>
                    <div>Add Filter</div>
                  </Flex>
                </Link>
              </Layout.Group>
              <Table
                {...{
                  actionPaths,
                  actionStatuses,
                  append,
                  dataKey: `${currentRoute?.slug}-filters`,
                  fields,
                  form,
                  handleReset,
                  isLoading,
                  remove,
                  tags,
                  replace,
                }}
              />
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  actionPaths: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  actionStatuses: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    setValue: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
};

Filters.defaultProps = {
  actionPaths: [],
  actionStatuses: [],
  isLoading: false,
  tags: [],
};

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Filters
 *
 * Provides form fields to control the data returned from the api.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { form, assets } = props;

  const frequency = form?.getValues('frequency');

  const minimumDate = useMemo(() => {
    let date;

    if (['WEEKS', 'MONTHS'].includes(frequency)) {
      date = dayjs(new Date()).subtract(2, 'year').format('MM/DD/YYYY');
    } else {
      date = dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY');
    }

    return date;
  }, [frequency]);

  /**
   * Date range restrictions.
   */
  useEffect(() => {
    const dateFrom = form?.getValues('dateRange.start');

    if (['WEEKS', 'MONTHS'].includes(frequency)) {
      // Only allow maximum of 2 years in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'));
      }
    }

    if (frequency === 'DAYS') {
      // Only allow maximum of 6 months in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'));
      }
    }
  }, [frequency]);

  /**
   * Validates the fields
   */
  useEffect(() => {
    form?.validate(
      `attributeTypes`,
      () => {},
      () => {}
    );

    form?.validate(
      `recordType`,
      () => {},
      () => {}
    );

    form?.validate(
      `frequency`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.start`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.end`,
      () => {},
      () => {}
    );
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Operations/components/Filters/Filters.jsx">
      <Visibility>
        <Styles>
          <Grid css={{ position: 'relative' }}>
            <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
              <SelectInputMulti
                label="Attribute Type"
                placeholder="Please Select --"
                model="attributeTypes"
                options={[
                  { value: 'dob', label: 'DOB' },
                  { value: 'ssn', label: 'SSN' },
                ]}
                validators={{ required: true }}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
              <SelectInput
                label="Record Type"
                placeholder="Please Select --"
                model="recordType"
                options={[
                  { value: 'cip', label: 'CIP' },
                  { value: 'allTypes', label: 'All' },
                ]}
                validators={{ required: true }}
                onChange={() => {
                  form?.validate(
                    `recordType`,
                    () => {},
                    () => {}
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
              <SelectInput
                label="Frequency"
                placeholder="Please Select --"
                model="frequency"
                isClearable
                options={assets?.ListChronoUnits?.data
                  ?.map((chronoUnit) => {
                    return {
                      value: chronoUnit?.codeId,
                      label: chronoUnit?.codeDesc,
                    };
                  })
                  .filter((chronoUnit) => {
                    return ['DAYS', 'WEEKS', 'MONTHS'].includes(chronoUnit?.value);
                  })}
                validators={{ required: true }}
                onChange={() => {
                  form?.validate(
                    `frequency`,
                    () => {},
                    () => {}
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
              <Grid css={{ paddingLeft: 0 }}>
                <Grid.Col span={{ xs: '75%' }} css={{ paddingRight: 0, paddingLeft: 0 }}>
                  <DateRange
                    defaultStartDate={form?.getValues('dateRange.start')}
                    defaultEndDate={form?.getValues('dateRange.end')}
                    minimumDate={minimumDate}
                    maximumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                    onChange={(event = {}) => {
                      form?.setValue('dateRange.start', String(event?.startDate), {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      form?.setValue('dateRange.end', String(event?.endDate), {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      form?.validate(
                        `dateRange.start`,
                        () => {},
                        () => {}
                      );

                      form?.validate(
                        `dateRange.end`,
                        () => {},
                        () => {}
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: '25%' }}>
                  <Flex
                    justify="flex-start"
                    alignItems="flex-end"
                    alignContent="flex-end"
                    direction="row"
                    css={{ height: '100%' }}
                  >
                    <Button type="submit" variant="solid" isDisabled={!isEmpty(form?.formState?.errors)}>
                      Apply
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes.func,
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        attributeTypes: PropTypes.shape({
          shouldValidate: PropTypes.bool,
          shouldDirty: PropTypes.bool,
        }),
        recordType: PropTypes.shape({
          shouldValidate: PropTypes.bool,
          shouldDirty: PropTypes.bool,
        }),
        frequency: PropTypes.shape({
          shouldValidate: PropTypes.bool,
          shouldDirty: PropTypes.bool,
        }),
        dateRange: PropTypes.shape({
          start: PropTypes.shape({
            shouldValidate: PropTypes.bool,
            shouldDirty: PropTypes.bool,
          }),
          end: PropTypes.shape({
            shouldValidate: PropTypes.bool,
            shouldDirty: PropTypes.bool,
          }),
        }),
      }),
    }),
  }),
  assets: PropTypes.shape({
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeId: PropTypes.string,
          codeDesc: PropTypes.string,
        })
      ),
    }),
  }),
};

Filters.defaultProps = {
  form: {},
  assets: {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';

/**
 * Dates
 *
 * Displays dates affiliated with a risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Dates = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Dates/Dates.jsx">
      <Visibility>
        <Widget title="Dates" description="Date history for EID in Rem Hub" collapsible collapsed>
          {isEmpty(data) ? (
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
              <Alert title="There are no dates affiliated with this risk record." variant="info" />
            </Grid.Col>
          ) : (
            <Table data={data} />
          )}
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

Dates.propTypes = {
  data: PropTypes.shape({
    streamReceived: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    createdDate: PropTypes.string,
    replayStreamReceived: PropTypes.string,
  }),
};

Dates.defaultProps = {
  data: {},
};

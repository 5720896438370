import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';
import { dayjs } from '@abyss/web/tools/dayjs';

/**
 * GetRiskRecordsCount
 *
 * Retrieves count of risk records based on entrance criteria.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskRecordsCount = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': `/risk-records/count`,
      },
      data: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { queryKey: payload[0] };
    }

    if (!isUndefined(payload?.[1]?.filters)) {
      requestArgs.data.filters = payload?.[1]?.filters.map((filter) => {
        const theFilter = {
          column: filter?.field,
          condition: filter?.condition,
          value: filter?.value,
        };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(filter?.field)) {
          theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
        }

        if (filter?.field?.toLowerCase().includes('count')) {
          theFilter.value = Number(filter?.value);
        }

        return theFilter;
      });
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetRiskRecordsCount.js -> GetRiskRecordsCount() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Heading } from '@abyss/web/ui/Heading';
import { orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Table to show recommendations from source.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { recommendationResponses } = props;

  /**
   * renderCellDobLastModified
   *
   * Displays the date of birth last modified in a particular date/time format.
   *
   * @param value
   * @returns {*|string}
   */
  const renderCellStatusDate = ({ value }) => {
    return dayjs(value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column?.Header === 'Status Date') {
        column.Cell = renderCellStatusDate;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/TrustedValueHistory/components/Table/components/ExpansionRow/components/RecordActivity/RecordActivity.jsx">
      <Visibility>
        <Heading
          offset={5}
          css={{ marginBottom: 'var(--abyss-space-md) !important', marginTop: 'var(--abyss-space-sm)' }}
        >
          Recommendation Responses
        </Heading>
        <TableComponent
          {...{
            accessor: 'eidSearch-trustedValueHistory-RecommendationResponses',
            columns,
            configuration,
            rows: recommendationResponses,
            noDataMessage: 'No recommendation responses found.',
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  recommendationResponses: PropTypes.arrayOf(
    PropTypes.shape({
      statusCode: PropTypes.string,
      statusDate: PropTypes.string,
      assignedCode: PropTypes.string,
      assignedDescription: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  recommendationResponses: [],
};

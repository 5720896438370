import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { useApi } from '@src/context/Api';
import { Visibility } from '@src/components/Visibility';
import { Table } from './components/Table';

/**
 * Actions
 *
 * Provides the user with a screen listing the existing actions.
 *
 * @returns {Element}
 * @constructor
 */
export const Actions = () => {
  const { useApiQuery } = useApi();

  const [ListActions, { data, isLoading, isFetching, error }] = useApiQuery('ListActions');

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Actions/Actions.jsx">
      <Visibility>
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex direction="row" alignItems="center">
              <Heading offset={0}>Actions</Heading>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc' }}
              requestFunction={ListActions}
              requestKey="ListActions"
              rows={data?.content || []}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
            />
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

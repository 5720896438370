import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { isUndefined } from 'lodash';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useApi } from '@src/context/Api';
import { useDebounce } from '@src/hooks';
import { Visibility } from '@src/components/Visibility';
import fields from '../../../includes/fields.json';

/**
 * Search
 *
 * Provides the user with the ability to specify an action path name.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Search = (props) => {
  const { form, actionPath } = props;
  const { name: fieldName } = fields?.global;

  const name = form.getValues('name');

  const { useApiQuery } = useApi();

  const [GetValidActionPathName, { data }] = useApiQuery('GetValidActionPathName');

  /**
   * validateField
   */
  const validateField = () => {
    form.validate(
      'name',
      () => {},
      () => {}
    );
  };

  /**
   * sendRequest
   *
   * Makes a request to a remote API to validateCriteria whether the user provided action path name exists or not.
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const sendRequest = useCallback(async () => {
    if ((await form?.trigger('name')) === true && name !== actionPath?.name) {
      GetValidActionPathName({ name });
    }
  }, [name]);

  useEffect(() => {
    if (!isUndefined(data)) {
      const isValid = data;

      if (isValid === true) {
        form?.clearErrors('name');
      }

      if (isValid === false) {
        form?.setError('name', { type: 'custom', message: 'Name already in use.' }, { shouldFocus: true });
      }
    }
  }, [data]);

  /**
   * @TODO Needs description.
   */
  useEffect(() => {
    validateField();
  }, [name, actionPath?.name]);

  const debouncedRequest = useDebounce(sendRequest, 500);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/components/Header/components/Search.jsx">
      <Visibility>
        <TextInput
          {...fieldName}
          onChange={async (event) => {
            if (event?.target?.value?.length >= 3) {
              if ((await form?.trigger('name')) === true) {
                form?.register('name');
                form?.setError(
                  'name',
                  { type: 'custom', message: 'Verifying name is available...' },
                  { shouldFocus: true }
                );
              }
              debouncedRequest();
            }
          }}
          validators={{
            ...fieldName.validators,
            ...{
              validate: {
                customValidator: (value) => {
                  return fieldValidator(fieldName, value);
                },
              },
            },
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Search.propTypes = {
  actionPath: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setError: PropTypes.func,
    trigger: PropTypes.func,
    register: PropTypes.func,
    clearErrors: PropTypes.func,
    validate: PropTypes.func,
  }),
};

Search.defaultProps = {
  actionPath: {},
  form: {},
};

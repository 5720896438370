import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { abbrNum } from '@src/includes/functions';
import { Bar } from 'react-chartjs-2';
import { Card } from '@abyss/web/ui/Card';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import { Pagination } from '@abyss/web/ui/Pagination';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { Visibility } from '@src/components/Visibility';

/**
 * HorizontalBarChart
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {React.JSX.Element|string|*|string}
 * @constructor
 */
export const HorizontalBarChart = (props) => {
  const { data, riskCodes } = props;
  const chartRef = useRef(null);

  const [labels, setLabels] = useState([]);
  const [redTotals, setRedTotals] = useState([]);
  const [yellowTotals, setYellowTotals] = useState([]);

  /**
   * @TODO Needs description.
   */
  useEffect(() => {
    let theLabels = [];
    const theRedTotals = [];
    const theYellowTotals = [];

    const red = [1, 2, 3, 4, 5, 6, 71, 72, 73, 21, 22, 25];
    const yellow = [8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 23, 24];

    if (!isEmpty(data)) {
      const items = orderBy(data, ['countTotal'], ['desc']);

      items?.forEach((item) => {
        const riskCode = Number(String(item?.riskCode).replace('RC-', ''));

        if (red.includes(riskCode) || yellow.includes(riskCode)) {
          if (!theLabels.includes(item?.riskCode)) {
            theLabels.push(item?.riskCode);
          }

          if (!theRedTotals.includes(item?.countTotal) && red.includes(riskCode)) {
            theRedTotals.push(item?.countTotal);
          } else {
            theRedTotals.push(0);
          }

          if (!theYellowTotals.includes(item?.countTotal) && yellow.includes(riskCode)) {
            theYellowTotals.push(item?.countTotal);
          } else {
            theYellowTotals.push(0);
          }
        }
      });
    }

    if (theLabels !== labels) {
      theLabels = setLabels(theLabels);
    }

    if (theRedTotals !== redTotals) {
      setRedTotals(theRedTotals);
    }

    if (theYellowTotals !== yellowTotals) {
      setYellowTotals(theYellowTotals);
    }
  }, [data]);

  const pagination = usePagination({ pages: Math.ceil(labels?.length / 7), pageSize: 7 });

  /**
   * getMinimumSize
   *
   * @TODO Needs description
   *
   * @param size
   * @returns {number}
   */
  const getMinimumSize = (size = 0) => {
    let theSize = size;

    if (pagination?.state?.currentPage >= 1) {
      theSize = 6 * pagination?.state?.pageIndex;
    }

    return theSize;
  };

  /**
   * getMaximumSize
   *
   * @TODO Needs description
   *
   * @param size
   * @returns {number}
   */
  const getMaximumSize = (size = 6) => {
    let theSize = size;

    if (pagination?.state?.currentPage >= 1) {
      theSize = 6 * pagination?.state?.currentPage;
    }

    return theSize;
  };

  /**
   * scroller
   *
   * @TODO Needs description
   *
   * @param event
   * @param chart
   * @param thePagination
   */
  const scroller = (event, chart, thePagination) => {
    const currentTimestamp = performance.now();
    const eventTimestamp = event?.timeStamp;

    const theChart = chart;

    const dataLength = theChart.data.labels.length;

    if (event.deltaY > 0) {
      if (theChart.config.options.scales.y.max >= dataLength) {
        theChart.config.options.scales.y.min = dataLength - 7;
        theChart.config.options.scales.y.max = dataLength - 1;
      } else {
        theChart.config.options.scales.y.min += 1;
        theChart.config.options.scales.y.max += 1;
      }
    }

    if (event.deltaY < 0) {
      if (theChart.config.options.scales.y.min <= 0) {
        theChart.config.options.scales.y.min = 0;
        theChart.config.options.scales.y.max = 6;
      } else {
        theChart.config.options.scales.y.min -= 1;
        theChart.config.options.scales.y.max -= 1;
      }
    }

    if (
      Math.ceil(currentTimestamp) !== Math.floor(eventTimestamp) &&
      Math.floor(currentTimestamp) !== Math.ceil(eventTimestamp) &&
      Math.ceil(currentTimestamp) !== Math.ceil(eventTimestamp) &&
      Math.floor(currentTimestamp) !== Math.floor(eventTimestamp) &&
      theChart.config.options.scales.y.min >= 0 &&
      theChart.config.options.scales.y.min <= dataLength &&
      theChart.config.options.scales.y.max >= 0 &&
      theChart.config.options.scales.y.max <= dataLength
    ) {
      const currentPage = Math.floor(theChart.config.options.scales.y.min / thePagination?.pageCount);

      if (currentPage !== thePagination?.state?.currentPage) {
        thePagination?.gotoPage(currentPage);
      } else {
        theChart.update();
      }
    }
  };

  /**
   * @TODO Needs description
   */
  useEffect(() => {
    const chart = chartRef.current;

    const scrollingCallback = (event) => {
      scroller(event, chart, pagination);
    };

    if (!isUndefined(chart?.canvas)) {
      chart?.canvas?.addEventListener('wheel', scrollingCallback);
    }

    return () => {
      if (!isUndefined(chart?.canvas)) {
        chart?.canvas?.removeEventListener('wheel', scrollingCallback);
      }
    };
  }, [pagination?.pageIndex, pagination?.pageCount]);

  return (
    <ErrorHandler location="src/widgets/RiskCodes/components/HorizontalBarChart/HorizontalBarChart.jsx">
      <Visibility>
        <Card.Section>
          <Bar
            ref={chartRef}
            options={{
              animation: {
                duration: 0, // general animation time
              },
              hover: {
                animationDuration: 0, // duration of animations when hovering an item
              },
              responsiveAnimationDuration: 0, // animation duration after a resize
              indexAxis: 'y',
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label(context) {
                      return `Total: ${Number(context.raw).toLocaleString('en-US')}`;
                    },
                    title(context) {
                      const theRiskCode = String(context?.[0]?.label).replace('RC-', '');
                      const riskCodeReason = riskCodes?.find((riskCode) => {
                        if (riskCode?.codeId === theRiskCode) {
                          return riskCode;
                        }
                        return undefined;
                      });

                      return !isUndefined(riskCodeReason) ? riskCodeReason?.codeDesc : '';
                    },
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    callback(value) {
                      return `${abbrNum(Number(value), 2)}`;
                    },
                  },
                },
                y: {
                  min: getMinimumSize(),
                  max: getMaximumSize(),
                },
              },
            }}
            data={{
              labels: labels || [],
              datasets: [
                {
                  label: 'Red',
                  backgroundColor: 'rgba(252, 240, 240, 0.75)',
                  borderColor: 'rgba(196, 0, 0, 1.0)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(196, 0, 0, 1.0)',
                  hoverBorderColor: 'rgba(196, 0, 0, 1.0)',
                  data: redTotals,
                  grouped: false,
                },
                {
                  label: 'Yellow',
                  backgroundColor: 'rgba(255, 252, 240, 0.75)',
                  borderColor: 'rgba(194, 78, 20, 1.0)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(194, 78, 20, 1.0)',
                  hoverBorderColor: 'rgba(194, 78, 20, 1.0)',
                  data: yellowTotals,
                  grouped: false,
                },
              ],
            }}
          />
          <Flex className="pagination" justify="center">
            <Pagination id="riskCodes-pagination" {...pagination} variant="compact" />
          </Flex>
        </Card.Section>
      </Visibility>
    </ErrorHandler>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      countTotal: PropTypes.number,
      riskCode: PropTypes.string,
    })
  ),
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      codeDesc: PropTypes.string,
      codeId: PropTypes.string,
    })
  ),
};

HorizontalBarChart.defaultProps = {
  data: [],
  riskCodes: [],
};

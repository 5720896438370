import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { SourcesTooltip } from '@src/tooltips';
import { Table as TableComponent } from '@src/components/Table-static';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table: Assignments
 *
 * Displays a list of action paths a risk record is assigned to.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { record } = props;

  /**
   * renderCellActionPath
   *
   * Displays the name of the action path within a cell. View action path if active otherwise edit on click.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellActionPath = ({ row, cell }) => {
    return (
      <Link
        href={`/action-paths/${row?.original?.actionPathId}${
          String(row?.original?.status).toLowerCase() !== 'active' ? '/edit' : ''
        }`}
      >
        {cell?.value}
      </Link>
    );
  };

  /**
   * renderCellLastUpdated
   *
   * Displays the date and time of when the action path was last updated within a cell.
   *
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdated = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellStatus
   *
   * Displays the current status of the action path within a cell.
   *
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellStatus = ({ cell }) => {
    const badges = {
      active: 'success',
      inactive: 'error',
      draft: 'neutral',
    };

    if (!isUndefined(badges[cell?.value.toLowerCase()])) {
      return (
        <Badge variant={badges[cell?.value.toLowerCase()]} outline>
          <span style={{ textTransform: 'capitalize' }}>{cell?.value.toLowerCase()}</span>
        </Badge>
      );
    }

    return cell.value;
  };

  /**
   * renderCellSourcesRemediated
   *
   * Displays a concatenated/truncated list of impacted sources within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellSourcesRemediated = ({ row, cell }) => {
    if (!isEmpty(row?.original?.recordActions)) {
      const sources = [];

      row?.original?.recordActions.forEach((recordAction) => {
        if (!isEmpty(recordAction?.recordReferences)) {
          recordAction?.recordReferences?.forEach((recordReference) => {
            if (!sources.includes(recordReference?.source)) {
              sources.push(recordReference?.source);
            }
          });
        }
      });

      return (
        <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }

    return cell.value;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Action Path') {
        column.Cell = renderCellActionPath;
      }

      if (column.Header === 'Last Updated') {
        column.Cell = renderCellLastUpdated;
      }

      if (column.Header === 'Status') {
        column.Cell = renderCellStatus;
      }

      if (column.Header === 'Source(s) Remediated') {
        column.Cell = renderCellSourcesRemediated;
      }

      return column;
    });
  }, []);

  /**
   * Rows for table.
   */
  const rows = useMemo(() => {
    return record?.recordActionPaths || [];
  }, []);

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/ExpansionRow/components/Assignments/Assignments.jsx">
      <Visibility>
        <TableComponent
          {...{
            columns,
            rows,
            configuration,
            dataKey: `actionPaths-RiskRecord-actionPaths-${record?.eid}`,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  record: PropTypes.shape({
    eid: PropTypes.string,
    recordActionPaths: PropTypes.arrayOf(
      PropTypes.shape({
        actionPathId: PropTypes.string,
        status: PropTypes.string,
        recordActions: PropTypes.arrayOf(
          PropTypes.shape({
            recordReferences: PropTypes.arrayOf(
              PropTypes.shape({
                source: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
  }),
};

Table.defaultProps = {
  record: {},
};

/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { AnalyzeRisksButton } from '@src/components/AnalyzeRisksButton';
import { AssociatedEids } from '@src/widgets/AssociatedEids';
import { Badge } from '@abyss/web/ui/Badge';
import { dayjs } from '@abyss/web/tools/dayjs';
import { EntranceCriteria } from '@src/widgets/EntranceCriteria';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { RemediationActions } from '@src/widgets/RemediationActions';
import { Text } from '@abyss/web/ui/Text';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';
import { ActionPathNotes } from '@src/widgets/ActionPathNotes';
import { SourceRecordsRemediated } from './components/SourceRecordsRemediated';
import { Skeleton } from './components/Skeleton';
import { RecordAgeInPath } from './components/RecordAgeInPath';
import { InQueue } from './components/InQueue';

/**
 * View
 *
 * Provides the user with a screen to view information about the action path.
 *
 * @returns {Element}
 * @constructor
 */
export const View = () => {
  const { currentRoute } = useRoutesContext();
  const actionPathId = currentRoute?.params?.id;
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const { useApiQueries } = useApi();

  const theQueries = useMemo(() => {
    if (isUndefined(actionPathId)) {
      return [];
    }
    return [
      {
        key: 'GetActionPath',
        args: { actionPathId },
      },
      {
        key: 'GetActionPathStatistics',
        args: { actionPathId },
      },
    ];
  }, [actionPathId]);

  const queries = useApiQueries(theQueries);

  const actionPath = queries?.GetActionPath?.data;
  const actionPathStatistics = queries?.GetActionPathStatistics?.data;

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((queryKey) => {
          const query = queries[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queries, theQueries]);

  /**
   * Redirect the user back to the list screen if action path status is not "ACTIVE"
   */
  useLayoutEffect(() => {
    if (!isEmpty(actionPath) && actionPath?.status !== 'ACTIVE') {
      router?.navigate('/action-paths');
      toast.show({
        id: 'action-path-status-error',
        title: 'Invalid Status',
        message: (
          <React.Fragment>
            Not allowed to view action path with status other than <code>active</code>.
          </React.Fragment>
        ),
        variant: 'error',
      });
    }
  }, [actionPath]);

  if (isLoading) {
    return <Skeleton />;
  }

  /**
   * Mode
   *
   * displays the mode of the action path.
   *
   * @returns {Element}
   * @constructor
   */
  const Mode = () => {
    if (actionPath?.manualAssociation === false) {
      return (
        <Badge
          icon={<IconSymbol variant="outline" style={{ color: 'inherit' }} size={16} icon="autorenew" />}
          variant="success"
          outline
        >
          Automatic
        </Badge>
      );
    }
    return (
      <Badge
        icon={<IconSymbol variant="outline" style={{ color: 'inherit' }} size={16} icon="back_hand" />}
        variant="warning"
        outline
      >
        Manual
      </Badge>
    );
  };

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/View.jsx">
      <Visibility>
        <Grid>
          <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
            <Heading offset={0}>
              <Layout.Group>
                <div>Action Path: {actionPath?.name}</div>
                <div>
                  <Mode />
                </div>
              </Layout.Group>
            </Heading>
            <Text color="var(--abyss-colors-gray6)">
              Last Updated:{' '}
              <time dateTime={actionPathStatistics?.date} style={{ color: 'black' }}>
                {dayjs(actionPathStatistics?.date).format('dddd, MMMM D, YYYY h:mm A')} CST
              </time>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <AnalyzeRisksButton actionPath={actionPath} variant="button" />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '30%',
            }}
          >
            <InQueue data={actionPathStatistics?.statusCodeCounts || {}} />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '50%',
            }}
          >
            <Layout.Stack alignLayout="left" alignItems="top" grow space={themeConfiguration?.theme?.space?.lg}>
              <SourceRecordsRemediated data={actionPathStatistics?.remediationStats || {}} />
              <RecordAgeInPath data={actionPathStatistics?.recordAgeStats || {}} />
            </Layout.Stack>
          </Grid.Col>
        </Grid>

        <Grid>
          {actionPath?.manualAssociation === false && (
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <EntranceCriteria filters={actionPath?.entranceCriteria} />
            </Grid.Col>
          )}

          {actionPath?.manualAssociation === true && (
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <AssociatedEids actionPath={actionPath} />
            </Grid.Col>
          )}

          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '50%',
            }}
          >
            <RemediationActions
              assignments={actionPath?.assignments}
              scopeCode={actionPath?.actionPathScopeCode}
              remediationType={actionPath?.remediationMethod}
            />
          </Grid.Col>
        </Grid>
        {!isUndefined(actionPath?.note) && (
          <Grid>
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <ActionPathNotes actionPath={actionPath} />
            </Grid.Col>
          </Grid>
        )}
      </Visibility>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { DateInput } from '@abyss/web/ui/DateInput';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, merge } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { useForm } from '@abyss/web/hooks/useForm';
import fields from './includes/fields.json';

const { actualRecordCount, endDate, eventTypeCode, expectedRecordCount, impactedSystems, notes, startDate, title } =
  fields;

/**
 * FormModal
 *
 * Prompts the user with a popup window allowing them to edit the event.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FormModal = (props) => {
  const { isOpen, setIsOpen, currentEvent, setCurrentEvent, handleSave, assets } = props;

  const defaultValues = {
    actualRecordCount: '',
    endDate: '',
    eventTypeCode: '',
    expectedRecordCount: '',
    impactedSystems: [],
    notes: '',
    startDate: '',
    title: '',
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});

  const form = useForm({ defaultValues });

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (!isEmpty(currentEvent)) {
      const data = merge({}, defaultValues, {
        actualRecordCount: String(currentEvent?.actualRecordCount),
        endDate: dayjs(currentEvent?.endDate).format('MM/DD/YYYY'),
        eventTypeCode: currentEvent?.eventTypeCode,
        expectedRecordCount: String(currentEvent?.expectedRecordCount),
        impactedSystems: currentEvent?.impactedSystems,
        notes: currentEvent?.notes,
        startDate: dayjs(currentEvent?.startDate).format('MM/DD/YYYY'),
        title: currentEvent?.title,
      });

      if (isEmpty(initialValues)) {
        setInitialValues(data);
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [currentEvent]);

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setCurrentEvent({});
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();

    const payload = !isEmpty(currentEvent) ? { ...currentEvent, ...submittedValues } : { ...submittedValues };

    payload.expectedRecordCount = Number(payload?.expectedRecordCount);
    payload.actualRecordCount = Number(payload?.actualRecordCount);
    payload.endDate = dayjs(payload?.endDate).format('YYYY-MM-DD');
    payload.startDate = dayjs(payload?.startDate).format('YYYY-MM-DD');
    payload.impactedSystems = payload?.impactedSystems?.sort();

    await handleSave(payload);
  };

  const formValues = form?.getValues();

  const isEqual = (object1, object2) => {
    return JSON.stringify(object1) === JSON.stringify(object2);
  };

  /**
   * form validation rules, toggling the save button enablement.
   */
  useEffect(() => {
    if (!isEmpty(currentEvent)) {
      if (isEqual(formValues, initialValues) || !form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }

    if (isEmpty(currentEvent)) {
      if (!form?.formState?.isValid) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [currentEvent, form?.formState.isDirty, form?.formState?.isValid, formValues, form?.formState?.defaultValues]);

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Events/List/components/FormModal/FormModal.jsx">
      <Modal title={!isEmpty(currentEvent) ? 'Edit Event' : 'Create Event'} isOpen={isOpen} onClose={handleClose}>
        <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <TextInput
                  {...title}
                  validators={{
                    ...title.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(title, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `title`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <SelectInput
                  {...eventTypeCode}
                  options={assets?.ListEventTypes?.data?.map((eventType) => {
                    return {
                      label: eventType?.codeDesc,
                      value: eventType?.codeId,
                    };
                  })}
                  validators={{
                    ...eventTypeCode.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(eventTypeCode, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `eventTypeCode`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <DateInput
                  {...startDate}
                  validators={{
                    ...startDate.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(startDate, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `startDate`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <DateInput
                  {...endDate}
                  validators={{
                    ...endDate.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(endDate, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `endDate`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <TextInput
                  {...expectedRecordCount}
                  validators={{
                    ...expectedRecordCount.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(expectedRecordCount, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `expectedRecordCount`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <TextInput
                  {...actualRecordCount}
                  validators={{
                    ...actualRecordCount.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(actualRecordCount, value);
                        },
                      },
                    },
                  }}
                  onChange={() => {
                    form?.validate(
                      `actualRecordCount`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <TextInputArea
                  {...notes}
                  onChange={() => {
                    form?.validate(
                      `notes`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: '50%',
                }}
              >
                <SelectInputMulti
                  {...impactedSystems}
                  validators={{
                    ...impactedSystems.validators,
                    ...{
                      validate: {
                        customValidator: (value) => {
                          return fieldValidator(impactedSystems, value);
                        },
                      },
                    },
                  }}
                  options={assets?.ListImpactedSystems?.data?.map((impactedSystem) => {
                    return {
                      label: impactedSystem?.codeDesc,
                      value: impactedSystem?.codeId,
                    };
                  })}
                  onChange={(value) => {
                    form?.setValue('impactedSystems', value?.sort());
                    form?.validate(
                      `impactedSystems`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" type="submit" isDisabled={isDisabled}>
                {!isEmpty(currentEvent) ? 'Save' : 'Create'}
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

FormModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  currentEvent: PropTypes.shape({
    actualRecordCount: PropTypes.number,
    endDate: PropTypes.string,
    eventTypeCode: PropTypes.string,
    expectedRecordCount: PropTypes.number,
    impactedSystems: PropTypes.arrayOf(PropTypes.string),
    notes: PropTypes.string,
    startDate: PropTypes.string,
    title: PropTypes.string,
  }),
  setCurrentEvent: PropTypes.func,
  handleSave: PropTypes.func,
  assets: PropTypes.shape({
    ListEventTypes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListImpactedSystems: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
  }),
};

FormModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  currentEvent: {},
  setCurrentEvent: () => {},
  handleSave: () => {},
  assets: {},
};

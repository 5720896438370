import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { useForm } from '@abyss/web/hooks/useForm';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Filters
 *
 * Displays a form to filter the data displayed in the table.
 *
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { requestArgs, setRequestArgs } = props;

  const defaultValues = {
    dateRange: {
      start: dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'),
      end: dayjs(new Date()).add(6, 'months').format('MM/DD/YYYY'),
    },
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form?.formState;

  /**
   * handleSubmit
   *
   * Retrieves the filtered data from the remote API.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        setRequestArgs({
          ...requestArgs,
          ...{
            startDate: dayjs(submittedValues?.dateRange?.start).format('YYYY-MM-DD'),
            endDate: dayjs(submittedValues?.dateRange?.end).format('YYYY-MM-DD'),
          },
        });
      }
    },
    [isSubmitting, isValid, requestArgs]
  );

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Events/List/components/Table/components/Filters/Filters.jsx">
      <Visibility>
        <Styles>
          <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
            <Layout.Group alignLayout="left" alignItems="bottom">
              <div>
                <DateRange
                  isRequired={false}
                  isClearable={false}
                  defaultStartDate={form?.getValues('dateRange.start')}
                  defaultEndDate={form?.getValues('dateRange.end')}
                  minimumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                  maximumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                  onChange={(event = {}) => {
                    form?.setValue('dateRange.start', String(event?.startDate), {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    form?.setValue('dateRange.end', String(event?.endDate), {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    form?.validate(
                      `dateRange.start`,
                      () => {},
                      () => {}
                    );

                    form?.validate(
                      `dateRange.end`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </div>
              <div>
                <Button variant="solid" type="submit">
                  Apply
                </Button>
              </div>
            </Layout.Group>
          </FormProvider>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  requestArgs: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  setRequestArgs: PropTypes.func,
};

Filters.defaultProps = {
  requestArgs: {},
  setRequestArgs: () => {},
};

import fields from '@src/components/Filters/components/Table/includes/fields.json';
import PropTypes from 'prop-types';
import React from 'react';
import { Chip } from '@abyss/web/ui/Chip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isArray } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * EntranceCriteria
 *
 * Shows the currently applied filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EntranceCriteria = (props) => {
  const { form } = props;

  const filters = form?.getValues('filters');

  /**
   * getLabel
   *
   * @TODO Needs description.
   *
   * @param theCondition
   * @returns {*}
   */
  const getLabel = (theCondition) => {
    const matchedCondition = fields?.condition?.options?.find((condition) => {
      return condition?.value === theCondition;
    });
    return matchedCondition?.label;
  };

  /**
   * theFilter
   *
   * @TODO Needs description.
   *
   * @param theFilter
   * @returns {*}
   */
  const getFilter = (theFilter) => {
    const matchedFilter = fields?.field?.options?.find((filter) => {
      return filter?.value === theFilter;
    });
    return matchedFilter?.label;
  };

  /**
   * getValue
   *
   * @TODO Needs description.
   *
   * @param theValue
   * @returns {*}
   */
  const getValue = (theValue) => {
    let value = theValue;

    if (isArray(theValue)) {
      value = theValue.join(', ');
    }

    return value;
  };

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/Header/components/EntranceCriteria/EntranceCriteria.jsx">
      <Visibility>
        <Styles>
          <Layout.Group>
            <Text fontWeight="bold">Entrance Criteria:</Text>
            {filters?.map((filter) => {
              return (
                <Chip
                  key={`${JSON.stringify(filter)}`}
                  text={
                    <Layout.Group space={6}>
                      <div>{getFilter(filter?.field)}:</div>
                      {getLabel(filter?.condition)}
                      <div>&quot;{getValue(filter?.value)}&quot;</div>
                    </Layout.Group>
                  }
                  icon={
                    <React.Fragment>
                      {filter?.field === 'TAGS' && <IconSymbol icon="label" />}
                      {filter?.field.toLowerCase().includes('date') && <IconSymbol icon="calendar_month" />}
                      {filter?.field.toLowerCase().includes('count') && <IconSymbol icon="numbers" />}
                      {filter?.field === 'ACTION_STATUS' && <IconSymbol icon="help_outline" />}
                    </React.Fragment>
                  }
                />
              );
            })}
          </Layout.Group>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

EntranceCriteria.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

EntranceCriteria.defaultProps = {
  form: {},
};

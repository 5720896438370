import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { Visibility } from '@src/components/Visibility';

/**
 * Skeleton
 *
 * Displays a roughed-in idea/placeholder of what the UI should look like while the data is fetching from the API.
 *
 * @returns {Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/components/Skeleton/Skeleton.jsx">
      <Visibility>
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.xl} width="50%" />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="30%" />
            </Layout.Stack>
          </Grid.Col>
        </Grid>
        <Divider color="transparent" />
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '80%',
            }}
          >
            <Layout.Group alignItems="left" grow space={themeConfiguration?.theme?.space?.md}>
              <AbyssSkeleton height="100%" />
              <div style={{ width: '100%' }}>
                <Layout.Stack alignItems="left" grow space={themeConfiguration?.theme?.space?.md}>
                  <AbyssSkeleton height={250} />
                  <AbyssSkeleton height={250} />
                </Layout.Stack>
              </div>
            </Layout.Group>
          </Grid.Col>
        </Grid>
        <Divider color="transparent" />
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Layout.Group alignItems="left" grow space={themeConfiguration?.theme?.space?.md}>
              <AbyssSkeleton height={400} />
              <AbyssSkeleton height={400} />
            </Layout.Group>
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

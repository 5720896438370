import React, { useMemo } from 'react';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { orderBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Table as TableComponent } from '@src/components/Table-query';
import { Visibility } from '@src/components/Visibility';
import PropTypes from 'prop-types';
import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a list of code categories within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    totalPages,
    totalRecords,
    setIsFormModalOpen,
    setCurrentEntity,
  } = props;

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        title: 'Edit',
        onClick: () => {
          setIsFormModalOpen(true);
          setCurrentEntity(row?.original);
        },
        icon: <IconSymbol icon="edit" />,
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            hideLabel
            before={<IconSymbol icon="more_vert" size={24} color="#6F6F6F" />}
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellIsValid
   *
   * display the value of the isValid field as a string
   *
   * @param value
   * @returns {string}
   */
  const renderCellIsValid = ({ value }) => {
    return String(value).toUpperCase();
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      if (column.accessor === 'isValid') {
        column.Cell = renderCellIsValid;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CodeCategories/List/components/Table/Table.jsx">
      <Visibility>
        <Styles>
          <TableComponent
            {...{
              columns,
              configuration,
              error,
              isLoading,
              requestArgs,
              requestFunction,
              requestKey,
              rows,
              totalPages,
              totalRecords,
              noDataMessage: 'No code categories found.',
            }}
          />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    variant: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      categoryCode: PropTypes.string,
      categoryDesc: PropTypes.string,
      isValid: PropTypes.bool,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
  setIsFormModalOpen: PropTypes.func,
  setCurrentEntity: PropTypes.func,
};

Table.defaultProps = {
  error: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 0,
  totalRecords: 0,
  setIsFormModalOpen: () => {},
  setCurrentEntity: () => {},
};

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { useCountdown } from '@abyss/web/hooks/useCountdown';
import { useIdleTimer } from 'react-idle-timer';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Visibility } from '@src/components/Visibility';

/**
 * Ask user to continue their session after 25 minutes of activity. Giving the user another 5 minutes to confirm
 * activity before logging them out after a total of 30 minutes of inactivity.
 *
 * @dependency: react-idle-timer
 * @documentation: https://idletimer.dev/docs/getting-started/installation
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const IdleTimeout = (props) => {
  const { handleLogout } = props;

  const countdown = useCountdown({
    onCompleted: handleLogout,
  });

  const modal = useOverlay('IdleTimeOut');

  const { isPrompted } = useIdleTimer({
    onIdle: () => {
      if (modal.getState()?.isOpen !== true) {
        countdown.setCountdownTime(5 * 60 * 1000); // 5 Minutes
        modal.open();
      }
    },
    timeout: 25 * 60 * 1000, // 25 minutes
    events: [
      'DOMMouseScroll',
      'keydown',
      'mousedown',
      'mousemove',
      'mousewheel',
      'MSPointerDown',
      'MSPointerMove',
      'touchmove',
      'touchstart',
      'visibilitychange',
      'wheel',
    ],
  });

  /**
   * Outputs the remaining countdown time.
   *
   * @type {Function}
   */
  const CountDown = useCallback(() => {
    return (
      <Text>
        {countdown.remainingTime === 0 ? <strong>NOW</strong> : 'in '}
        {countdown.hours > 1 && <strong>{`${countdown.hours} hours `}</strong>}
        {countdown.hours === 1 && <strong>{`${countdown.hours} hour `}</strong>}
        {countdown.minutes > 1 && <strong>{`${countdown.minutes} minutes `}</strong>}
        {countdown.minutes === 1 && <strong>{`${countdown.minutes} minute `}</strong>}
        {countdown.seconds > 1 && <strong>{`${countdown.seconds} seconds `}</strong>}
        {countdown.seconds === 1 && <strong>{`${countdown.seconds} second `}</strong>}
      </Text>
    );
  }, [countdown]);

  /**
   * Reset the countdown timer.
   *
   * @type {(function(): void)|*}
   */
  const handleReset = useCallback(() => {
    countdown.resetCountdown();
  }, [countdown]);

  return (
    <ErrorHandler location="src/components/IdleTimeout/IdleTimeout.jsx">
      <Visibility>
        {isPrompted && (
          <Modal
            title="Are you still there?"
            titleAlign="center"
            model="IdleTimeOut"
            css={{
              'abyss-modal-header-container': { marginTop: 'var(--abyss-space-md)' },
            }}
            onClose={handleReset}
          >
            <div
              style={{
                border: '7px solid white',
                background: 'white',
                borderRadius: '50%',
                position: 'absolute',
                top: '-2%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <IconBrand icon="alert" size="xl" />
            </div>
            <Modal.Section
              css={{
                textAlign: 'center',
              }}
            >
              <Text>
                Your session will expire <CountDown /> due to inactivity.
                <br />
                Click Continue to remain signed in.
              </Text>
            </Modal.Section>
            <Modal.Section>
              <Layout.Stack alignLayout="center" alignItems="center">
                <Button
                  variant="solid"
                  onClick={() => {
                    handleReset();
                    modal.close();
                  }}
                >
                  Continue Session
                </Button>
                <Button
                  variant="tertiary"
                  onClick={async () => {
                    handleReset();
                    modal.close();
                    await handleLogout();
                  }}
                >
                  <Link
                    href="/logout"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    Logout
                  </Link>
                </Button>
              </Layout.Stack>
            </Modal.Section>
          </Modal>
        )}
      </Visibility>
    </ErrorHandler>
  );
};

/* PropTypes Validation */
IdleTimeout.propTypes = {
  handleLogout: PropTypes.func,
};

/* Default PropTypes */
IdleTimeout.defaultProps = {
  handleLogout: null,
};

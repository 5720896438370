import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';
import { dayjs } from '@abyss/web/tools/dayjs';

/**
 * ListRiskRecords
 *
 * Retrieves a list of risk records from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const ListRiskRecords = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/risk-records/searchByFilters',
      },
      params: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params.page = payload?.[1]?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params.size = payload?.[1]?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params.sort = payload?.[1]?.sort;
    }

    if (!isUndefined(payload?.[1]?.filters)) {
      const searchFilters = payload?.[1]?.filters?.map((filter) => {
        const theFilter = {
          column: filter?.field,
          condition: filter?.condition,
          value: filter?.value,
        };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(filter?.field)) {
          theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
        }

        if (filter?.field?.toLowerCase().includes('count')) {
          theFilter.value = Number(filter?.value);
        }

        return theFilter;
      });

      requestArgs.data = {
        filters: searchFilters,
      };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListRiskRecords.js -> ListRiskRecords() -> error:', theError);

    throw error;
  }
};

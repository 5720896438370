import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { config } from '@abyss/web/tools/config';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';
import { Visibility } from '@src/components/Visibility';

/**
 * AssociatedEids
 *
 * Displays associated EID's in a modal with a link to HCM.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AssociatedEids = (props) => {
  const { associatedEids, isOpen, setIsOpen } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/components/AssociatedEids/AssociatedEids.jsx">
      <Visibility>
        <Modal
          title="Associated EID's"
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Modal.Section>
            <Layout.Stack space={themeConfiguration?.theme?.space?.xs} alignLayout="left" alignItems="left" grow>
              {!isEmpty(associatedEids) &&
                associatedEids.map((associatedEid) => {
                  return (
                    <Link key={associatedEid} openNewWindow href={`${config('HCM_URL')}/${associatedEid}/`}>
                      {associatedEid}
                    </Link>
                  );
                })}
            </Layout.Stack>
          </Modal.Section>
        </Modal>
      </Visibility>
    </ErrorHandler>
  );
};

AssociatedEids.propTypes = {
  associatedEids: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

AssociatedEids.defaultProps = {
  associatedEids: [],
  isOpen: false,
  setIsOpen: () => {},
};

import React, { useEffect, useState } from 'react';
import { DateInput } from '@abyss/web/ui/DateInput';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Events } from '@src/widgets/Events';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty } from 'lodash';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { RiskCodes } from '@src/widgets/RiskCodes';
import { UntrustedSourceRecords } from '@src/widgets/UntrustedSourceRecords';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { RedEntities } from './components/RedEntities';
import fields from './includes/fields.json';
import { YellowEntities } from './components/YellowEntities';

/**
 * Risk
 *
 * Provides the user with a screen to view various statistics relating to risk records.
 *
 * @returns {Element}
 * @constructor
 */
export const Risk = () => {
  const { currentRoute } = useRoutesContext();

  const { reportDate: fieldReportDate } = fields;
  const [reportDate, setReportDate] = useState(dayjs().format('MM/DD/YYYY'));
  const [isLoading, setIsLoading] = useState(true);

  const { useApiQueries } = useApi();

  const theQueries = [
    {
      key: 'GetRiskDashboardStatistics',
      args: { date: dayjs(reportDate).format('YYYY-MM-DD') },
    },
    'ListRiskCodes',
  ];

  const queries = useApiQueries(theQueries);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((queryKey) => {
          const query = queries[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queries, theQueries]);

  return (
    <ErrorHandler location="src/routes/private/Dashboardss/screens/RiskDashboard/RiskDashboard.jsx">
      <Visibility>
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Heading offset={0}>{currentRoute?.screenTitle}</Heading>
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <DateInput {...fieldReportDate} value={reportDate} onChange={setReportDate} />
          </Grid.Col>
          <LoadingOverlay
            loadingTitle="Loading..."
            loadingMessage="Your request is being processed."
            ariaLoadingLabel="Your request is being processed."
            isLoading={isLoading}
          >
            <Grid.Col span={{ xs: '100%' }}>
              <Grid>
                <Grid.Col span={{ xs: '50%' }}>
                  <Grid>
                    <Grid.Col span={{ xs: '70%' }}>
                      <RedEntities data={queries?.GetRiskDashboardStatistics?.data?.redEntityDetails || {}} />
                    </Grid.Col>
                    <Grid.Col span={{ xs: '30%' }}>
                      <YellowEntities data={queries?.GetRiskDashboardStatistics?.data?.yellowEntityDetails || {}} />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={{ xs: '100%' }}>
              <Grid>
                <Grid.Col span={{ xs: '50%' }}>
                  <RiskCodes
                    data={queries?.GetRiskDashboardStatistics?.data?.riskCodeStats || {}}
                    riskCodes={queries?.ListRiskCodes?.data || []}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: '50%' }}>
                  <UntrustedSourceRecords
                    data={queries?.GetRiskDashboardStatistics?.data?.untrustedSourceStats || {}}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ xs: '100%' }}>
                  <Events reportDate={reportDate} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </LoadingOverlay>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

import { isEmpty, isUndefined } from 'lodash';
import { config } from '@abyss/web/tools/config';

/**
 * abbrNum
 *
 * Abbreviates a number.
 *
 * @param number
 * @param decPlaces
 * @returns {*}
 */
export function abbrNum(number = 0, decPlaces = 0) {
  if (String(number).length > 6 && decPlaces === 0) {
    return abbrNum(number, 1);
  }

  let theNumber = number;

  // 2 decimal places => 100, 3 => 1000, etc
  const decimalPlaces = 10 ** decPlaces;

  // Enumerate theNumber abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the theNumber is bigger or equal do the abbreviation
    if (size <= theNumber) {
      // Here, we multiply by decimalPlaces, round, and then divide by decimalPlaces.
      // This gives us nice rounding to a particular decimal place.
      theNumber = Math.round((theNumber * decimalPlaces) / size) / decimalPlaces;

      // Handle special case where we round up to the next abbreviation
      if (theNumber === 1000 && i < abbrev.length - 1) {
        theNumber = 1;
        // eslint-disable-next-line no-plusplus
        i++;
      }

      // Add the letter for the abbreviation
      theNumber += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return theNumber;
}

/**
 * parallelFetch
 *
 * Fetches provided requests in parallel.
 *
 * @param requests
 * @returns {Promise<{}>}
 */
export async function parallelFetch(requests = {}) {
  const response = {};

  try {
    if (isEmpty(requests)) {
      return response;
    }

    const promises = Object.keys(requests).map(async (key) => {
      const request = requests[key];

      let result;

      if (!isUndefined(request?.callback)) {
        if (!isUndefined(request?.args)) {
          result = await request?.callback(request?.args);
        } else {
          result = await request?.callback();
        }

        response[key] = result;
      }
    });

    await Promise.all(promises);
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('includes/functions.js -> parallelFetch() -> error:', theError);
  }

  return response;
}

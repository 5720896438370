import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Link } from '@abyss/web/ui/Link';
import { merge, orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-query';
import { useUserContext } from '@src/context/User';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

/**
 * Table: AssociatedEids
 *
 * Displays a list of EID's associated with an action path within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    headerLeft,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    setEids,
    setIsOpen,
    totalPages,
    totalRecords,
  } = props;

  const { roles } = useUserContext();

  /**
   * renderCellActions
   *
   * Displays a dropdown menu with actions for the row.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    return (
      <Button
        className="eid-remove-button"
        variant="ghost"
        rounded
        size="$sm"
        onClick={() => {
          setEids([row?.original?.eid]);
          setIsOpen(true);
        }}
      >
        <IconSymbol className="eid-remove-icon" variant="outlined" icon="remove_circle_outline" />
      </Button>
    );
  };

  /**
   * renderCellEid
   *
   * Renders the EID in the cell with a link to the eid search results.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellEid = ({ value }) => {
    return <Link href={`/analysis/eid-search/${value}`}>{value}</Link>;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(
      configuration?.initialColumns.filter((column) => {
        return !(column.accessor === 'actions' && !roles.includes('State.Write'));
      }),
      ['order'],
      ['asc']
    ).map((item) => {
      const column = item;

      if (column?.Header === 'EID') {
        column.Cell = renderCellEid;
      }

      if (column?.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  const bulkActions = [
    {
      onClick: ({ getSelectedRows }) => {
        const theEids = getSelectedRows().map((row) => {
          return row?.eid;
        });
        setEids([...theEids]);
        setIsOpen(true);
      },
      icon: <IconSymbol className="eid-remove-icon" variant="outlined" icon="remove_circle_outline" />,
      label: 'Remove Association',
      isSeparated: false,
    },
  ];

  const bulkActionsConfig = roles.includes('State.Write')
    ? {
        showSelection: true,
        bulkActions,
      }
    : {};

  return (
    <ErrorHandler location="src/tables/AssociatedEids/Table.jsx">
      <Visibility>
        <Styles>
          <TableComponent
            {...{
              columns,
              configuration: merge({}, configuration, bulkActionsConfig),
              error,
              headerLeft,
              isLoading,
              requestArgs,
              requestFunction,
              requestKey,
              rows,
              totalPages,
              totalRecords,
              noDataMessage: "There are no associated eid's.",
            }}
          />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.string,
  headerLeft: PropTypes.element,
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        ]),
      })
    ),
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      eid: PropTypes.string,
    })
  ),
  setEids: PropTypes.func,
  setIsOpen: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: '',
  headerLeft: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  setEids: () => {},
  setIsOpen: () => {},
  totalPages: 1,
  totalRecords: 0,
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#filtersContainer': {
    marginTop: 'calc(calc(var(--abyss-space-xl) + var(--abyss-space-sm)) * -1)',
    '#viewsContainer': {
      borderBottom: '1px solid var(--abyss-colors-gray4)',
      borderLeft: '1px solid var(--abyss-colors-gray4)',
      borderRight: '1px solid var(--abyss-colors-gray4)',
      '.abyss-flex-root': {
        '> div:first-of-type': {
          width: 'calc(100%  - 90px)',
          paddingRight: 'var(--abyss-space-md)',
        },
        '.abyss-select-input-multi-root': {
          position: 'relative',
          "*[class*='isInvalid-true']": {
            position: 'absolute',
            top: 'calc(100% - 1px)',
            right: 'unset',
            bottom: 'unset',
            marginRight: 'var(--abyss-space-lg)',
            padding: '4px',
            zIndex: '999',
            backgroundColor: 'var(--abyss-colors-error2)',
            border: '1px solid var(--abyss-colors-error1)',
          },
        },
      },
    },
  },
});

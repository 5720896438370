import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { isNull } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { Popover } from '@abyss/web/ui/Popover';
import { useApi } from '@src/context/Api';
import { Visibility } from '@src/components/Visibility';
import { PopoverContent } from './components/PopoverContent';

/**
 * ExportSharePoint
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ExportSharePoint = (props) => {
  const { exportDetails, exportRefetch, form, pollingRate, riskRecordCount, setPollingRate } = props;

  const actionPathId = form?.getValues('id');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { useApiMutation, clearApiCache } = useApi();

  const [ScheduleExport] = useApiMutation('ScheduleExport');

  /**
   * toggles the enabled/disabled status of the export button.
   */
  useEffect(() => {
    if (isNull(pollingRate) || !['RUNNING', 'SCHEDULED'].includes(exportDetails?.status)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [pollingRate, exportDetails]);

  /**
   * handleScheduleExport
   *
   * @TODO Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleExport = async () => {
    setIsDisabled(true);
    await ScheduleExport(
      { actionPathId },
      {
        onSuccess: () => {
          clearApiCache(['GetExportDetails']);
          exportRefetch();
          setIsDisabled(true);
          setPollingRate(5000);
        },
        onError: () => {
          clearApiCache(['GetExportDetails']);
          exportRefetch();
          setIsDisabled(false);
          setPollingRate(null);
        },
      }
    );
  };

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/Header/components/ExportSharePoint/ExportSharePoint.jsx">
      <Visibility>
        <Popover
          content={<PopoverContent setIsOpen={setIsOpen} handleExport={handleExport} actionPathId={actionPathId} />}
          open={isOpen}
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          position="bottom"
          align="start"
          alignOffset={50}
          width={275}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            before={
              <IconMaterial
                icon="file_upload"
                color={isDisabled ? 'var(--abyss-colors-gray5)' : 'var(--abyss-colors-interactive1)'}
              />
            }
            hideIcon
            isDisabled={isDisabled}
            onClick={async (event) => {
              event?.preventDefault();
              if (riskRecordCount > 1000000) {
                setIsOpen(true);
              } else {
                setIsDisabled(true);
                await handleExport();
              }
            }}
          >
            Export to SharePoint
          </Link>
        </Popover>
      </Visibility>
    </ErrorHandler>
  );
};

ExportSharePoint.propTypes = {
  exportDetails: PropTypes.shape({
    status: PropTypes.string,
  }),
  exportRefetch: PropTypes.func,
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  pollingRate: PropTypes.number,
  riskRecordCount: PropTypes.number,
  setPollingRate: PropTypes.func,
};

ExportSharePoint.defaultProps = {
  exportDetails: {},
  exportRefetch: () => {},
  form: {},
  pollingRate: null,
  riskRecordCount: 0,
  setPollingRate: () => {},
};

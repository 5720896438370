import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { Visibility } from '@src/components/Visibility';

/**
 * Skeleton
 *
 * This is displayed to resemble an idea of what the final rendered output will look like while the data is loading.
 *
 * @returns {Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="src/tables/RiskRecords/components/ExpansionRow/components/Skeleton.jsx">
      <Visibility>
        <Grid css={{ margin: 0 }}>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left">
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width={300} />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width={200} />
            </Layout.Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
            </Layout.Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '25%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
            </Layout.Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '25%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
              <Layout.Group grow>
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
                <AbyssSkeleton height={themeConfiguration?.theme?.space?.md} width="100%" />
              </Layout.Group>
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

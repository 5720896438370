import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNil, isUndefined } from 'lodash';
import { RiskRecords as Table } from '@src/tables';
import { useApi } from '@src/context/Api';
import { useInterval } from '@abyss/web/hooks/useInterval';
import { Visibility } from '@src/components/Visibility';

/**
 * RiskRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const RiskRecords = (props) => {
  const { assets, currentStep, form } = props;

  const riskCodes = assets?.ListRiskCodes?.data || [];

  const [requestParameters, setRequestParameters] = useState({
    filters: [],
    size: 25,
    page: 0,
    sort: 'eid,asc',
  });

  const [pollingRate, setPollingRate] = useState(null);

  const filters = form.getValues('filters');
  const actionPathId = form?.getValues('id');

  const { useApiQuery, clearApiCache } = useApi();

  const [ListRiskRecords, { data: riskData, isLoading: riskIsLoading, isFetching: riskIsFetching, error: riskError }] =
    useApiQuery('ListRiskRecords');

  const [GetExportDetails, { data: exportData, refetch: exportRefetch }] = useApiQuery('GetExportDetails');

  const [GetRiskRecordsCount, { data: countData }] = useApiQuery('GetRiskRecordsCount');

  useEffect(() => {
    if (isUndefined(exportData) && !isNil(actionPathId)) {
      GetExportDetails({ actionPathId });
    }

    if (isUndefined(countData)) {
      GetRiskRecordsCount({ filters });
    }
  }, [exportData, countData, actionPathId]);

  /**
   * @TODO - Needs description.
   */
  useEffect(() => {
    if (filters !== requestParameters.filters) {
      setRequestParameters({ ...requestParameters, ...{ filters } });
    }
  }, [filters]);

  /**
   * Start polling for status changes.
   */
  useEffect(() => {
    if (['RUNNING', 'SCHEDULED'].includes(exportData?.status)) {
      setPollingRate(5000);
    } else {
      setPollingRate(null);
    }
  }, [exportData]);

  useInterval(async () => {
    clearApiCache(['GetExportDetails']);
    GetExportDetails({ actionPathId });
  }, pollingRate);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/RiskRecords/RiskRecords.jsx">
      <Visibility>
        <Table
          allowExport
          currentStep={currentStep}
          entranceCriteria={filters}
          error={riskError}
          exportDetails={exportData}
          form={form}
          isLoading={riskIsLoading || riskIsFetching}
          pollingRate={pollingRate}
          requestArgs={{ page: 0, size: 25, sort: 'eid,asc', filters }}
          requestFunction={ListRiskRecords}
          requestKey="ListRiskRecords"
          riskCodes={riskCodes}
          riskRecordCount={countData}
          rows={riskData?.content || []}
          setPollingRate={setPollingRate}
          exportRefetch={exportRefetch}
          totalPages={riskData?.totalPages || 1}
          totalRecords={riskData?.totalElements || 0}
        />
      </Visibility>
    </ErrorHandler>
  );
};

RiskRecords.propTypes = {
  assets: PropTypes.shape({
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          code: PropTypes.string,
          description: PropTypes.string,
        })
      ),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

RiskRecords.defaultProps = {
  assets: {},
  currentStep: {},
  form: {},
};

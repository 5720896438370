import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isUndefined } from 'lodash';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Visibility } from '@src/components/Visibility';
import { Wizard } from '../../components/Wizard';

/**
 * Create
 *
 * Presents the user with a step-by-step (wizard) experience to compose an action path.
 *
 * @returns {Element}
 * @constructor
 */
export const Create = () => {
  const [isManual, setIsManual] = useState(false);

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();

  /**
   * Determines if the user is creating a manual action path.
   */
  useEffect(() => {
    if (path?.includes('manual')) {
      setIsManual(true);
    } else {
      setIsManual(false);
    }
  }, [path]);

  /**
   * Handle redirects based on the current step number and manual association.
   */
  useEffect(() => {
    if (isUndefined(routeParams?.step) && !isUndefined(path) && !path?.includes('step/1')) {
      router?.navigate(`${path}/step/1`);
    }
  }, [path, routeParams]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Create/Create.jsx">
      <Visibility>
        <Grid>
          <Grid.Col>
            <Heading offset={0}>{isManual ? 'Create Manual Action Path' : 'Create Action Path'}</Heading>
          </Grid.Col>
        </Grid>
        <Wizard mode="create" isManual={isManual} />
      </Visibility>
    </ErrorHandler>
  );
};

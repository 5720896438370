import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Box } from '@abyss/web/ui/Box';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { abbrNum } from '@src/includes/functions';

/**
 * RecordAgeInPath
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RecordAgeInPath = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/components/RecordAgeInPath/RecordAgeInPath.jsx">
      <Visibility>
        <Widget title="Record Age In Path" description="How long records are sitting in the Action Path">
          <Layout.Group grow space={themeConfiguration?.theme?.space?.xl}>
            <Box css={{ padding: 'var(--abyss-space-lg)', backgroundColor: 'var(--abyss-colors-success2)' }}>
              <Layout.Stack alignLayout="center">
                <Text>
                  <small>Minimum Time</small>
                </Text>
                <Text>
                  <strong>
                    {abbrNum(data?.minDays)} {abbrNum(data?.minDays) === 1 ? 'day' : 'days'}
                  </strong>
                </Text>
              </Layout.Stack>
            </Box>
            <Box css={{ padding: 'var(--abyss-space-lg)', backgroundColor: 'var(--abyss-colors-warning2)' }}>
              <Layout.Stack alignLayout="center">
                <Text>
                  <small>Average Time</small>
                </Text>
                <Text>
                  <strong>
                    {abbrNum(data?.avgDays)} {abbrNum(data?.avgDays) === 1 ? 'day' : 'days'}
                  </strong>
                </Text>
              </Layout.Stack>
            </Box>
            <Box css={{ padding: 'var(--abyss-space-lg)', backgroundColor: 'var(--abyss-colors-error2)' }}>
              <Layout.Stack alignLayout="center">
                <Text>
                  <small>Minimum Time</small>
                </Text>
                <Text>
                  <strong>
                    {abbrNum(data?.maxDays)} {abbrNum(data?.maxDays) === 1 ? 'day' : 'days'}
                  </strong>
                </Text>
              </Layout.Stack>
            </Box>
          </Layout.Group>
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

RecordAgeInPath.propTypes = {
  data: PropTypes.shape({
    minDays: PropTypes.number,
    avgDays: PropTypes.number,
    maxDays: PropTypes.number,
  }),
};

RecordAgeInPath.defaultProps = {
  data: {},
};

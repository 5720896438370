import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { ApiProvider } from '@src/context/Api';
import { config } from '@abyss/web/tools/config';
import { PrintProvider } from '@abyss/web/ui/PrintProvider';
import { RoutesProvider } from '@src/context/Routes';
import { useIsAuthenticated } from '@azure/msal-react';
import { UserProvider } from '@src/context/User';
import { VisibilityProvider } from '@src/context/Visibility';

/**
 * defaultValues
 *
 * Default global context values.
 *
 * @type {{currentEnvironment: string, environments: {prod: string, stage: string, dev: string, local:build: string,
 *   local: string}, setAuthentication: defaultValues.setAuthentication, currentVersion: string}}
 */
const defaultValues = {
  currentEnvironment: '',
  currentVersion: '0.0.0',
  environments: {
    prod: 'Production',
    stage: 'Stage',
    dev: 'Development',
    local: 'Local',
    'local:dev': 'Local',
    'local:build': 'Local',
  },
  setAuthentication: () => {},
};

/**
 * ApplicationContext
 *
 * Creates the global application context.
 *
 * @type {React.Context<{currentEnvironment: string, environments: {prod: string, stage: string, dev: string,
 *   local:build: string, local: string}, setAuthentication: defaultValues.setAuthentication, currentVersion: string}>}
 */
const ApplicationContext = createContext(defaultValues);

/**
 * useApplicationContext
 *
 * Allows components to access the global application context.
 *
 * @returns {{currentEnvironment: string, environments: {prod: string, stage: string, dev: string, local:build: string,
 *   local: string}, setAuthentication: setAuthentication, currentVersion: string}}
 */
export const useApplicationContext = () => {
  return useContext(ApplicationContext);
};

/**
 * ApplicationProvider
 *
 * Handles setting the context to be used globally throughout the application.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ApplicationProvider = (props) => {
  const { children } = props;

  const msalAuth = useIsAuthenticated();
  const isAuthenticated = config('APP_ENV') === 'local' ? true : msalAuth;

  const value = useMemo(() => {
    return {
      ...defaultValues,
      ...{
        currentEnvironment: defaultValues.environments[config('APP_ENV')],
        currentVersion: config('DD_VERSION') || document.head.querySelector('meta[name=version]')?.content || '0.0.0',
      },
    };
  }, []);

  return (
    <ApplicationContext.Provider value={value}>
      {isAuthenticated === true ? (
        <ApiProvider>
          <UserProvider>
            <RoutesProvider>
              <PrintProvider>
                <VisibilityProvider>{children}</VisibilityProvider>
              </PrintProvider>
            </RoutesProvider>
          </UserProvider>
        </ApiProvider>
      ) : (
        <VisibilityProvider>{children}</VisibilityProvider>
      )}
    </ApplicationContext.Provider>
  );
};

ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { useEffect, useState } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Table } from '@abyss/web/ui/Table';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { Skeleton } from './components/Skeleton';
import { EventType } from './components/EventType';

/**
 * View
 *
 * Provides the user with a screen to view information about the action path.
 *
 * @returns {Element}
 * @constructor
 */
export const View = () => {
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const { currentRoute } = useRoutesContext();
  const eventId = currentRoute?.params?.eventId;

  const { useApiQuery, useApiQueries } = useApi();

  const [GetEvent, { data: event, isLoading, isFetching }] = useApiQuery('GetEvent');

  const theAssets = ['ListEventTypes', 'ListImpactedSystems'];
  const assets = useApiQueries(theAssets);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Fetches the event data if the event ID is defined and the event data is not already loaded.
   */
  useEffect(() => {
    if (eventId && isUndefined(event)) {
      GetEvent({ eventId });
    }
  }, [eventId]);

  if (isLoading || isFetching || isLoadingAssets) {
    return <Skeleton />;
  }

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Events/View/View.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
              <Heading offset={0}>{event?.title}</Heading>
            </Grid.Col>
            <Grid.Col span={{ xs: '50%' }}>
              <div className="static-table">
                <Table.Container title="">
                  <Table.TableBody>
                    <Table.Row>
                      <Table.Column>Event Type</Table.Column>
                      <Table.Cell>
                        <EventType eventTypeCode={event?.eventTypeCode} eventTypes={assets?.ListEventTypes?.data} />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Event Date(s)</Table.Column>
                      <Table.Cell>
                        {dayjs(event?.startDate).format('MM/DD/YYYY')} - {dayjs(event?.endDate).format('MM/DD/YYYY')}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column
                        css={event?.impactedSystems.length > 1 ? { verticalAlign: 'top' } : { verticalAlign: 'middle' }}
                      >
                        Impacted Systems
                      </Table.Column>
                      <Table.Cell>
                        <Layout.Stack alignLayout="left" alignItems="left" grow>
                          {event?.impactedSystems?.map((impactedSystem) => {
                            const system = assets?.ListImpactedSystems?.data?.find((asset) => {
                              return asset?.codeId === impactedSystem;
                            });
                            return <div>{system?.codeDesc}</div>;
                          })}
                        </Layout.Stack>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Actual Records</Table.Column>
                      <Table.Cell>{Number(event?.actualRecordCount).toLocaleString('en-US')}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Expected Records</Table.Column>
                      <Table.Cell>{Number(event?.expectedRecordCount).toLocaleString('en-US')}</Table.Cell>
                    </Table.Row>
                  </Table.TableBody>
                </Table.Container>
              </div>
            </Grid.Col>
            <Grid.Col span={{ xs: '50%' }}>
              <div className="static-table">
                <Table.Container title="">
                  <Table.TableBody>
                    <Table.Row>
                      <Table.Column>Created By</Table.Column>
                      <Table.Cell>{event?.createdBy}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Create Date</Table.Column>
                      <Table.Cell>{dayjs(event?.createdDate).format('MM/DD/YYYY')}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Last Modified By</Table.Column>
                      <Table.Cell>{event?.lastModifiedBy}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Column>Last Modified Date</Table.Column>
                      <Table.Cell>{dayjs(event?.lastModifiedDate).format('MM/DD/YYYY')}</Table.Cell>
                    </Table.Row>
                  </Table.TableBody>
                </Table.Container>
              </div>
            </Grid.Col>
            <Grid.Col span={{ xs: '60%' }}>
              <div className="static-table">
                <Table.Container title="">
                  <Table.TableBody>
                    <Table.Row>
                      <Table.Column css={{ verticalAlign: 'top' }}>Notes</Table.Column>
                      <Table.Cell>{event?.notes}</Table.Cell>
                    </Table.Row>
                  </Table.TableBody>
                </Table.Container>
              </div>
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

/**
 * InQueue
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const InQueue = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/components/InQueue/InQueue.jsx">
      <Visibility>
        <Widget title="In Queue" description="Entity Action Path Statuses">
          <div
            style={{
              height: '100%',
              margin: '0px auto',
              maxWidth: '500px',
              minHeight: '278px',
              maxHeight: '250px',
              position: 'relative',
            }}
          >
            <Doughnut
              options={{
                animation: {
                  duration: 0, // general animation time
                },
                hover: {
                  animationDuration: 0, // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                maintainAspectRatio: false,
                responsive: true,
              }}
              data={{
                labels: ['Active', 'Resolved', 'Canceled', 'Invalidated'],
                datasets: [
                  {
                    label: '# of records',
                    data: [data?.active, data?.resolved, data?.canceled, data?.invalidated],
                    backgroundColor: [
                      'rgba(128, 97, 188, 0.6)',
                      'rgba(111, 193, 177, 0.6)',
                      'rgba(163, 42, 46, 0.6)',
                      'rgba(245, 183, 0, 0.6)',
                    ],
                    hoverBackgroundColor: [
                      'rgba(128, 97, 188, 1.0)',
                      'rgba(111, 193, 177, 1.0)',
                      'rgba(163, 42, 46, 1.0)',
                      'rgba(245, 183, 0, 1.0)',
                    ],
                    borderColor: [
                      'rgba(128, 97, 188, 1.0)',
                      'rgba(111, 193, 177, 1.0)',
                      'rgba(163, 42, 46, 1.0)',
                      'rgba(245, 183, 0, 1.0)',
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
          <Flex justify="space-between" css={{ marginTop: 'var(--abyss-space-lg)' }}>
            <Layout.Stack alignLayout="left" alignItems="top" grow space={1}>
              <div style={{ textAlign: 'left' }}>
                <strong>Total: {data?.total}</strong>
              </div>
              <div style={{ textAlign: 'left' }}>New: {data?.invalidated}</div>
              <div style={{ textAlign: 'left' }}>Active: {data?.active}</div>
            </Layout.Stack>
            <Layout.Stack alignLayout="right" alignItems="top" grow space={1}>
              <div>&nbsp;</div>
              <div style={{ textAlign: 'right' }}>Resolved: {data?.resolved}</div>
              <div style={{ textAlign: 'right' }}>Canceled: {data?.canceled}</div>
            </Layout.Stack>
          </Flex>
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

InQueue.propTypes = {
  data: PropTypes.shape({
    active: PropTypes.number,
    resolved: PropTypes.number,
    canceled: PropTypes.number,
    invalidated: PropTypes.number,
    total: PropTypes.number,
  }),
};

InQueue.defaultProps = {
  data: {},
};

import React, { useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { useApi } from '@src/context/Api';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';
import { Table } from './components/Table';
import { FormModal } from './components/FormModal';

/**
 * List
 *
 * Provides the user with a screen listing the existing code categories.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const { useApiQuery, useApiMutation, clearApiCache } = useApi();

  const [ListCodeCategories, { data, isLoading, isFetching, error, refetch }] = useApiQuery('ListCodeCategories');
  const [SaveCodeCategory] = useApiMutation('SaveCodeCategory');

  const { toast } = useToast();

  /**
   * handleSave
   *
   * Handles the saving of a code category.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-code-category';

    toast.show({
      id: `${toastId}-info`,
      title: 'Saving Code Category...',
      message: 'Code Category is preparing to save.',
      isLoading: true,
      ariaLoadingLabel: 'Saving Code Category',
      variant: 'info',
      autoClose: false,
    });

    await SaveCodeCategory(payload, {
      onSuccess: () => {
        clearApiCache(['ListCodeCategories']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          title: 'Saved Code Category',
          message: `Code Category has been saved.`,
          variant: 'success',
        });
      },
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          title: 'Code Category Save Failed',
          message: `Unable to save code category.`,
          variant: 'error',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CodeCategories/List/List.jsx">
      <Visibility>
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex direction="row" alignItems="center">
              <Heading offset={0}>Code Categories</Heading>
              <Button
                before={<IconSymbol icon="add" />}
                css={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
                size="$sm"
                variant="outline"
                onClick={() => {
                  setIsFormModalOpen(true);
                  setCurrentEntity({});
                }}
                isDisabled={isLoading || isFetching}
              >
                Create
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              requestArgs={{
                page: 0,
                size: 25,
                sort: 'categoryCode,asc',
              }}
              requestFunction={ListCodeCategories}
              requestKey="ListCodeCategories"
              rows={data?.content || []}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
              refetch={refetch}
              setIsFormModalOpen={setIsFormModalOpen}
              setCurrentEntity={setCurrentEntity}
            />
            {isFormModalOpen && (
              <FormModal
                isOpen={isFormModalOpen}
                setIsOpen={setIsFormModalOpen}
                currentEntity={currentEntity}
                setCurrentEntity={setCurrentEntity}
                handleSave={handleSave}
              />
            )}
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

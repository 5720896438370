import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from '@abyss/web/ui/Accordion';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Indicator } from '@abyss/web/ui/Indicator';
import { isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';

/**
 * Attribute
 *
 * Displays reported information regarding the risk record's affiliated DOB or SSN.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Attribute = (props) => {
  const { ireRiskRecord, attributeType } = props;

  const [trustedValue, setTrustedValue] = useState('');
  const [attributeData, setAttributeData] = useState({});

  /**
   * store the attribute values and their respective sources and corresponding record IDs.
   */
  useEffect(() => {
    if (!isUndefined(ireRiskRecord?.remediationFindings)) {
      /**
       * find the attribute
       */
      const theAttribute = ireRiskRecord?.remediationFindings.find((attribute) => {
        return attribute.attributeType === attributeType;
      });

      const theTrustedValue = theAttribute?.trustedValue;

      const values = {
        [theTrustedValue]: [],
      };

      /**
       * store trusted sources and their respective record IDs with the trusted value
       */
      ireRiskRecord?.trustedRecordSources?.forEach((record) => {
        const trustedRecord = {
          source: record?.source,
          id: record?.recordId,
        };

        if (!values[theTrustedValue].includes(trustedRecord)) {
          values[theTrustedValue].push(trustedRecord);
        }
      });

      /**
       * store untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        if (isUndefined(values[record?.value])) {
          values[record?.value] = [];
        }
      });

      /**
       * store untrusted sources and their respective record IDs with the untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        const untrustedRecord = {
          source: record?.source,
          id: record?.recordId,
        };

        if (!values[record?.value].includes(untrustedRecord)) {
          values[record?.value].push(untrustedRecord);
        }
      });

      if (theTrustedValue !== trustedValue) {
        setTrustedValue(theTrustedValue);
      }

      if (values !== attributeData) {
        setAttributeData(values);
      }
    }
  }, [ireRiskRecord]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Attribute/Attribute.jsx">
      <Visibility>
        <Widget
          title={`Attribute Type: ${String(attributeType).toUpperCase()}`}
          description="Identified Attribute with Identity Risk"
          collapsible
          collapsed={false}
        >
          <Accordion type="multiple" isCollapsible>
            {Object.keys(attributeData).map((attributeValue, index) => {
              const sources = attributeData[attributeValue];
              const sourceCount = Object.keys(sources)?.length;

              return (
                <Accordion.Item key={`${attributeValue}`} value={attributeValue}>
                  <Accordion.Trigger>
                    <Accordion.Header>
                      <Layout.Group>
                        <div>
                          {String(attributeValue) === String(trustedValue) ? (
                            <IconSymbol icon="check_circle" variant="outlined" color="var(--abyss-colors-success1)" />
                          ) : (
                            <IconSymbol icon="error" variant="outlined" color="var(--abyss-colors-error1)" />
                          )}
                        </div>
                        <div>
                          {sourceCount > 1 ? (
                            <div style={{ width: 'auto' }}>
                              <Indicator
                                label={sourceCount}
                                showZero={false}
                                withBorder
                                color="var(--abyss-colors-info1)"
                              >
                                <div style={{ marginRight: 'var(--abyss-space-sm)' }}> {attributeValue}</div>
                              </Indicator>
                            </div>
                          ) : (
                            <React.Fragment>{attributeValue}</React.Fragment>
                          )}
                        </div>
                      </Layout.Group>
                    </Accordion.Header>
                  </Accordion.Trigger>
                  <Accordion.Content>
                    <Table rows={sources} trusted={index === 0} />
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

Attribute.propTypes = {
  ireRiskRecord: PropTypes.shape({
    remediationFindings: PropTypes.arrayOf(
      PropTypes.shape({
        attributeType: PropTypes.string,
        trustedValue: PropTypes.string,
        untrustedRecordSources: PropTypes.arrayOf(
          PropTypes.shape({
            source: PropTypes.string,
            recordId: PropTypes.string,
          })
        ),
      })
    ),
    trustedRecordSources: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        recordId: PropTypes.string,
      })
    ),
  }),
  attributeType: PropTypes.string,
};

Attribute.defaultProps = {
  ireRiskRecord: {},
  attributeType: '',
};

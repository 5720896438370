import { isEmpty } from 'lodash';

/**
 * validateManualStep
 *
 * validates the current step of the wizard for manual actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @returns {*|boolean}
 */
function validateManualStep(currentStep, validName, formErrors) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  if (theStep?.order === 2) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  return theStep.isValid;
}

/**
 * validateAutomaticStep
 *
 * validates the current step of the wizard for automatic actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @returns {*}
 */
function validateAutomaticStep(currentStep, validName, formErrors) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    theStep.isValid = validName && !!isEmpty(formErrors?.filters);
  }

  if (theStep?.order === 2) {
    theStep.isValid = validName;
  }

  if (theStep?.order === 3) {
    theStep.isValid = validName && !!isEmpty(formErrors?.remediation);
  }

  if (theStep?.order === 4) {
    theStep.isValid = validName && !!isEmpty(formErrors?.filters) && !!isEmpty(formErrors?.remediation);
  }

  return theStep.isValid;
}

/**
 * isValidStep
 *
 * validates the current step of the wizard
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @param isManual
 * @returns {*|boolean}
 */
export function isValidStep(currentStep, validName, formErrors, isManual) {
  const theStep = { ...currentStep };

  if (isManual) {
    theStep.isValid = validateManualStep(currentStep, validName, formErrors);
  } else {
    theStep.isValid = validateAutomaticStep(currentStep, validName, formErrors);
  }

  return theStep.isValid;
}

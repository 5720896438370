import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { chartTypes } from './includes/chartTypes';

/**
 * Chart
 *
 * A chart is a visual representation of data, often utilizing graphical elements such as lines, bars, or symbols to
 * convey information and patterns.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Chart = (props) => {
  const { data, isLogarithmicScale, legendLimit, maxHeight, minHeight, showLegend, tableConfiguration, type } = props;

  const ChartComponent = chartTypes?.[type]?.component;

  return (
    <ErrorHandler location="src/components/Chart/Chart.jsx">
      <Visibility>
        {!isEmpty(maxHeight) && <style>{`.chartStyles { max-height: ${maxHeight}}`}</style>}
        {!isEmpty(minHeight) && <style>{`.chartStyles { min-height: ${minHeight}}`}</style>}
        <Styles className="chartStyles">
          <Grid>
            {isEmpty(data) ? (
              <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
                <Alert title="No data available" variant="info" />
              </Grid.Col>
            ) : (
              <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
                <ChartComponent
                  data={data}
                  showLegend={showLegend}
                  tableConfiguration={tableConfiguration}
                  legendLimit={legendLimit}
                  isLogarithmicScale={isLogarithmicScale}
                />
              </Grid.Col>
            )}
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLogarithmicScale: PropTypes.bool,
  legendLimit: PropTypes.number,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  showLegend: PropTypes.bool,
  tableConfiguration: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  isLogarithmicScale: false,
  legendLimit: 10,
  maxHeight: '',
  minHeight: '',
  showLegend: true,
  tableConfiguration: {},
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Data containing sources and record ids for trusted and untrusted values.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, trusted } = props;

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header.includes('Source')) {
        if (trusted === true) {
          column.Header = 'Trusted Source';
        } else {
          column.Header = 'Untrusted Source';
        }
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Attribute/components/Table/Table.jsx">
      <Visibility>
        <TableComponent
          {...{
            columns,
            rows,
            configuration,
            dataKey: `EidSearch-Attribute-Table-${trusted ? 'trusted' : 'untrusted'}`,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  trusted: PropTypes.bool,
};

Table.defaultProps = {
  rows: [],
  trusted: true,
};

import PropTypes from 'prop-types';
import React from 'react';
import { abbrNum } from '@src/includes/functions';
import { Badge } from '@abyss/web/ui/Badge';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNull } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Visibility } from '@src/components/Visibility';

/**
 * TotalRecords
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TotalRecords = (props) => {
  const { riskRecordCount } = props;

  let count = isNull(riskRecordCount) ? 0 : riskRecordCount;

  if (riskRecordCount > 0) {
    count = abbrNum(riskRecordCount);
  }

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/Header/components/TotalRecords/TotalRecords.jsx">
      <Visibility>
        <Layout.Group>
          <div>
            <Text fontWeight="bold">Total Records:</Text>
          </div>
          <Tooltip content={`Total: ${Number(riskRecordCount).toLocaleString('en-US')}`}>
            <div>
              <Badge variant="info" outline css={{ fontWeight: 'bold' }}>
                {count}
              </Badge>
            </div>
          </Tooltip>
        </Layout.Group>
      </Visibility>
    </ErrorHandler>
  );
};

TotalRecords.propTypes = {
  riskRecordCount: PropTypes.number,
};

TotalRecords.defaultProps = {
  riskRecordCount: 0,
};

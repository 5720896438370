import fieldConfiguration from '@src/components/Filters/components/Table/includes/fields.json';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isArray, isUndefined } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table: EntranceCriteria
 *
 * Displays a list of entrance criteria (filters) within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows } = props;

  const field = fieldConfiguration;

  /**
   * Table configuration.
   */
  const state = useDataTable({
    ...configuration,
    ...{
      initialData: rows,
    },
  });

  /**
   * Make fields more "human-readable"
   */
  useEffect(() => {
    const filters = [];

    rows.forEach((row) => {
      const theFilter = {
        field: !isUndefined(row?.column) ? row?.column : row?.field,
        condition: row?.condition,
        value: row?.value,
      };

      const column = field?.field?.options?.find((item) => {
        return item?.value === theFilter?.field;
      });

      if (!isUndefined(column)) {
        theFilter.column = column.label;
      }

      const condition = field?.condition?.options?.find((item) => {
        return item?.value === theFilter?.condition;
      });

      if (!isUndefined(condition)) {
        theFilter.condition = condition.label;
      }

      if (isArray(theFilter?.value)) {
        theFilter.value = theFilter.value.join(', ');
      }

      filters.push(theFilter);
    });

    if (filters !== rows) {
      state.setData(filters);
    }
  }, [rows]);

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns;
  }, []);

  return (
    <ErrorHandler location="src/tables/ActionPaths/EntranceCriteria/Table.jsx">
      <Visibility>
        <TableComponent
          {...{
            rows,
            columns,
            configuration,
            dataKey: `actionPaths-entranceCriteria`,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

Table.defaultProps = {
  rows: [],
};

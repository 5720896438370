import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { abbrNum } from '@src/includes/functions';
import { Bar } from 'react-chartjs-2';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, orderBy } from 'lodash';
import { Visibility } from '@src/components/Visibility';

/**
 * HorizontalBarChart
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {React.JSX.Element|string}
 * @constructor
 */
export const HorizontalBarChart = (props) => {
  const { data } = props;

  const [labels, setLabels] = useState([]);
  const [actionPathTotals, setActionPathTotals] = useState([]);
  const [noActionPathTotals, setNoActionPathTotals] = useState([]);

  /**
   * @TODO Needs description.
   */
  useEffect(() => {
    let theLabels = [];
    const theActionPathTotals = [];
    const theNoActionPathTotals = [];

    if (!isEmpty(data)) {
      const items = orderBy(data, ['countTotal'], ['desc']);

      items?.forEach((item) => {
        if (!theLabels.includes(item?.untrustedSource)) {
          theLabels.push(item?.untrustedSource);
        }

        if (!theActionPathTotals.includes(item?.countActiveActionPath)) {
          theActionPathTotals.push(item?.countActiveActionPath);
        } else {
          theActionPathTotals.push(0);
        }

        if (!theNoActionPathTotals.includes(item?.countNoActiveActionPath)) {
          theNoActionPathTotals.push(item?.countNoActiveActionPath);
        } else {
          theNoActionPathTotals.push(0);
        }
      });
    }

    if (theLabels !== labels) {
      theLabels = setLabels(theLabels);
    }

    if (theActionPathTotals !== actionPathTotals) {
      setActionPathTotals(theActionPathTotals);
    }

    if (theNoActionPathTotals !== noActionPathTotals) {
      setNoActionPathTotals(theNoActionPathTotals);
    }
  }, [data]);

  return (
    <ErrorHandler location="src/widgets/UntrustedSourceRecords/components/HorizontalBarChart/HorizontalBarChart.jsx">
      <Visibility>
        <Bar
          options={{
            animation: {
              duration: 0, // general animation time
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            indexAxis: 'y',
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
            },
            scales: {
              x: {
                ticks: {
                  callback(value) {
                    return `${abbrNum(Number(value), 2)}`;
                  },
                },
              },
            },
          }}
          data={{
            labels: labels || [],
            datasets: [
              {
                label: 'Action Path',
                backgroundColor: 'rgba(111, 193, 177, 0.75)',
                borderColor: 'rgba(0, 124, 137, 1.0)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0, 124, 137, 1.0)',
                hoverBorderColor: 'rgba(0, 124, 137, 1.0)',
                data: actionPathTotals,
                grouped: false,
              },
              {
                label: 'No Action Path',
                backgroundColor: 'rgba(128, 97, 188, 0.75)',
                borderColor: 'rgba(66, 44, 136, 1.0)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(66, 44, 136, 1.0)',
                hoverBorderColor: 'rgba(66, 44, 136, 1.0)',
                data: noActionPathTotals,
                grouped: false,
              },
            ],
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      untrustedSource: PropTypes.string,
      countTotal: PropTypes.number,
      countActiveActionPath: PropTypes.number,
      countNoActiveActionPath: PropTypes.number,
    })
  ),
};

HorizontalBarChart.defaultProps = {
  data: [],
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Data containing categories of tags, and their respective tags.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows } = props;

  /**
   * renderCellStatus
   *
   * displays the color coded Status in a cell.
   *
   * @param cell
   * @returns {Element}
   */
  const renderCellStatus = ({ cell }) => {
    if (cell?.value === 'Added') {
      return (
        <Badge outline variant="success">
          Added
        </Badge>
      );
    }

    if (cell?.value === 'Removed') {
      return (
        <Badge outline variant="error">
          Removed
        </Badge>
      );
    }

    return null;
  };

  /**
   * renderCellLastModified
   *
   * Displays the changed date cell.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellLastModified = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Last Modified') {
        column.Cell = renderCellLastModified;
      }

      if (column.Header === 'Status') {
        column.Cell = renderCellStatus;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Tags/components/Table/Table.jsx">
      <Visibility>
        <TableComponent
          {...{
            columns,
            rows,
            configuration,
            dataKey: `EidSearch-Table-Tags`,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      category: PropTypes.string,
      tag: PropTypes.string,
      status: PropTypes.string,
      lastModified: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  rows: [],
};

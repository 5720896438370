import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { Button } from '@src/components/Button';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useApi } from '@src/context/Api';
import { useDebounce } from '@src/hooks';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Styles } from './includes/styles';
import fields from '../../../../components/Wizard/includes/fields.json';

/**
 * DuplicationModal
 *
 * Prompts the user with a popup window asking them to specify a new action path name before duplicating.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DuplicationModal = (props) => {
  const { isOpen, setIsOpen, actionPath, setActionPath, handleDuplication } = props;
  const { name: fieldName } = fields?.global;

  const form = useForm({ defaultValues: { name: actionPath?.name } });
  const name = form.getValues('name');

  const router = useRouter();

  const { useApiQuery } = useApi();

  const [GetValidActionPathName, { data }] = useApiQuery('GetValidActionPathName');

  /**
   * handleSave
   *
   * @TODO Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleSave = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      await handleDuplication({ id: actionPath?.id, name });
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/DuplicationModal/DuplicationModal.jsx -> handleSave() -> error:',
        theError
      );

      throw error;
    }
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      router?.navigate(`/`);
      router?.navigate(`/action-paths?highlight=${actionPath?.id}`);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/DuplicationModal/DuplicationModal.jsx -> handleClose() -> error:',
        theError
      );

      throw error;
    }
  };
  /**
   * sendRequest
   *
   * Makes a request to a remote API to validateCriteria whether the user provided action path name exists or not.
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const sendRequest = useCallback(async () => {
    if ((await form?.trigger('name')) === true) {
      GetValidActionPathName({ name });
    }
  }, [name]);

  useEffect(() => {
    if (!isUndefined(data)) {
      const isValid = data;

      if (isValid === true) {
        form?.clearErrors('name');
      }

      if (isValid === false) {
        form?.setError('name', { type: 'custom', message: 'Name already in use.' }, { shouldFocus: true });
      }
    }
  }, [data]);

  const debouncedRequest = useDebounce(sendRequest, 500);

  useEffect(() => {
    if (name === actionPath?.name) {
      form?.setError('name', { type: 'custom', message: 'Name already in use.' }, { shouldFocus: true });
    }
  }, [name, actionPath?.name]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/components/DuplicationModal/DuplicationModal.jsx">
      <FormProvider state={form} autoComplete="off" highlighted>
        <Modal
          title="Confirm Duplication"
          isOpen={isOpen}
          onClose={handleClose}
          footer={
            <Modal.Section>
              <Divider height={1} />
              <Layout.Group alignLayout="right">
                <Button variant="outline" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  onClick={handleSave}
                  isDisabled={!isEmpty(form?.formState?.errors) || name === actionPath?.name}
                >
                  Duplicate
                </Button>
              </Layout.Group>
            </Modal.Section>
          }
        >
          <Styles>
            <Modal.Section>
              <TextInput label="Current Action Path Name" value={actionPath?.name} isDisabled />
              <TextInput
                {...fieldName}
                label="New Action Path Name"
                hideLabel={false}
                onChange={async (event) => {
                  if (event?.target?.value?.length >= 3) {
                    if ((await form?.trigger('name')) === true) {
                      form?.register('name');
                      form?.setError(
                        'name',
                        { type: 'custom', message: 'Verifying name is available...' },
                        { shouldFocus: true }
                      );
                    }
                    debouncedRequest();
                  }
                }}
                validators={{
                  ...fieldName.validators,
                  ...{
                    validate: {
                      customValidator: (value) => {
                        return fieldValidator(fieldName, value);
                      },
                    },
                  },
                }}
              />
            </Modal.Section>
          </Styles>
        </Modal>
      </FormProvider>
    </ErrorHandler>
  );
};

DuplicationModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  actionPath: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  setActionPath: PropTypes.func,
  handleDuplication: PropTypes.func,
};

DuplicationModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  actionPath: {},
  setActionPath: () => {},
  handleDuplication: () => {},
};

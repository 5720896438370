import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { AggregationView } from '@src/widgets/AggregationView';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { Filters } from '@src/components/Filters';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined, orderBy, sortBy } from 'lodash';
import { isEqual } from 'lodash/lang';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { omitBy } from 'lodash/object';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { Text } from '@abyss/web/ui/Text';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import fields from './includes/fields.json';

/**
 * RiskAnalysis
 *
 * Screen to aid in identifying, assessing, and mitigating potential risks, aiming to minimize negative impacts and
 * maximize opportunities. Leads to action path wizard.
 *
 * @returns {Element}
 * @constructor
 */
export const RiskAnalysis = () => {
  const { currentRoute } = useRoutesContext();
  const router = useRouter();

  const defaultValues = {
    filters: [],
    views: [],
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form?.formState;

  const { useApiQueries, useApiQuery, queryClient } = useApi();

  const theAssets = [
    'ListTags',
    'ListActionStatuses',
    'ListRiskCodes',
    'ListViews',
    {
      key: 'ListActionPaths',
      args: { page: 0, size: 9999, sort: 'name,asc', statusList: ['ACTIVE'] },
    },
  ];
  const [theQueries, setTheQueries] = useState([]);

  const assets = useApiQueries(theAssets);
  const queries = useApiQueries(theQueries);

  const [ListRiskRecords, { data: riskData, isLoading: riskIsLoading, isFetching: riskIsFetching, error: riskError }] =
    useApiQuery('ListRiskRecords');

  const [GetRiskRecordsCount, { data: riskRecordsCount, countIsLoading, countIsFetching }] =
    useApiQuery('GetRiskRecordsCount');

  const [isLoadingAssets, setIsLoadingAssets] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [previouslySubmittedValues, setPreviouslySubmittedValues] = useState({});

  /**
   * Determines the overall loading state of all assets.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((queryKey) => {
          const query = assets[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (!isEmpty(queries)) {
      if (Object.keys(queries).length === theQueries.length) {
        if (
          isEmpty(
            Object.keys(queries).filter((queryKey) => {
              const query = queries[queryKey];
              return !(!query?.isLoading && !query?.isFetching);
            })
          )
        ) {
          setIsLoading(false);
        }
      }
    }
  }, [queries, theQueries]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        setPreviouslySubmittedValues(submittedValues);

        const queriesList = [];

        submittedValues?.views?.forEach((view) => {
          const theView = assets?.ListViews?.data.find((item) => {
            return item?.id === view;
          });

          if (view === 'risk-records') {
            ListRiskRecords({ page: 0, size: 25, sort: 'eid,asc', filters: submittedValues?.filters });
          } else {
            setIsLoading(true);
            queriesList.push({
              key: `GetAggregationView-${view}`,
              args: { view: theView?.id, filters: submittedValues?.filters },
            });
          }
        });
        setTheQueries(queriesList);
        GetRiskRecordsCount({ filters: submittedValues?.filters });

        const encoded = Buffer.from(JSON.stringify(submittedValues)).toString('base64');
        if (currentRoute?.params?.key !== encoded) {
          router?.navigate(`/analysis/risk-analysis/${encoded}`);
        }
      }
    },
    [isSubmitting, isValid, assets?.ListViews?.data]
  );

  /**
   * Validates the views field
   */
  useEffect(() => {
    if (isUndefined(currentRoute?.params?.key)) {
      form?.setValue('filters', defaultValues?.filters);
      form.validate(
        `filters.0.field`,
        () => {},
        () => {}
      );
      form?.setValue('views', defaultValues?.views);
      form.validate(
        `views`,
        () => {},
        () => {}
      );
      setTheQueries([]);
      setPreviouslySubmittedValues({});
      queryClient.removeQueries('ListRiskRecords');
    } else {
      form?.clearErrors('views');
    }
  }, [currentRoute?.params?.key]);

  /**
   * If filters are passed in the URL, apply them to the form.
   */
  useEffect(() => {
    if (currentRoute?.params?.key && !isLoadingAssets && !isSubmitting) {
      const decoded = Buffer.from(currentRoute?.params?.key, 'base64').toString('utf-8');
      const searchCriteria = JSON.parse(decoded);

      const formFilters = form?.getValues('filters');

      const theFilters = formFilters?.filter((formFilter) => {
        const item = omitBy(formFilter, isEmpty);

        return !isEmpty(item);
      });

      if (isEmpty(theFilters) && !isEmpty(searchCriteria?.filters)) {
        form?.setValue('filters', searchCriteria?.filters);
      }

      const views = form?.getValues('views');

      if (isEmpty(views) && !isEmpty(searchCriteria?.views)) {
        form?.setValue('views', searchCriteria?.views);
      }

      if (isValid) {
        handleSubmit(searchCriteria);
      }
    }
  }, [currentRoute?.params?.key, isSubmitting, isValid, isLoadingAssets]);

  /**
   * setLocalStorage
   *
   * Stores form data in local storage for action path wizard to use.
   *
   * @returns {Promise<void>}
   */
  const setLocalStorage = async () => {
    return localStorage.setItem('risk-analysis-filters', JSON.stringify(form?.getValues()));
  };

  const viewOptions = useMemo(() => {
    const options = assets?.ListViews?.data
      ?.filter((view) => {
        return view?.status === 'ACTIVE';
      })
      ?.map((view) => {
        return {
          label: view?.name,
          value: view?.id,
        };
      });

    options?.push({
      label: 'Risk Records Sample Data',
      value: 'risk-records',
    });

    const sortedOptions = sortBy(options, [
      (view) => {
        return view.label;
      },
    ]);

    const theViews = {};

    sortedOptions.forEach((option) => {
      let section = null;

      if (option?.value.includes('-count')) {
        section = 'Counts';
      } else if (option?.value.includes('-date')) {
        section = 'Dates';
      } else if (option?.value.includes('-tag')) {
        section = 'Tags';
      } else {
        section = 'Other';
      }

      if (isUndefined(theViews[section])) {
        theViews[section] = [];
      }

      if (!theViews[section].includes(option)) {
        theViews[section].push({
          ...option,
          ...{
            label: `${option.label} (${section})`,
          },
        });
      }
    });

    const selectableOptions = [];

    Object.keys(theViews).forEach((section) => {
      selectableOptions.push({
        section,
        items: theViews[section],
      });
    });

    return orderBy(selectableOptions, ['section'], ['asc']);
  }, [assets?.ListViews?.data]);

  const formValues = form?.watch();

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/RiskAnalysis/RiskAnalysis.jsx">
      <Visibility>
        <Styles>
          <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <Heading offset={0}>Risk Analysis</Heading>
              </Grid.Col>

              {isLoadingAssets ? (
                <Grid.Col span={{ xs: '100%' }}>
                  <Loader verticalAlignment="top" />
                </Grid.Col>
              ) : (
                <Grid.Col id="filtersContainer" span={{ xs: '100%' }}>
                  <Filters
                    {...{
                      actionPaths: assets?.ListActionPaths?.data,
                      actionStatuses: assets?.ListActionStatuses?.data,
                      form,
                      isLoading,
                      tags: assets?.ListTags?.data,
                    }}
                  />
                  <Box id="viewsContainer" height="">
                    <Grid>
                      <Grid.Col span={{ xs: '100%', sm: '100%', md: '50%' }}>
                        <Flex justify="space-evenly" alignItems="end">
                          <div>
                            <SelectInputMulti
                              {...fields?.views}
                              options={viewOptions}
                              onChange={() => {
                                form.validate(
                                  `views`,
                                  () => {},
                                  () => {}
                                );
                              }}
                              validators={{
                                ...fields?.views?.validators,
                                ...{
                                  validate: {
                                    customValidator: (value) => {
                                      return fieldValidator(fields?.views, value);
                                    },
                                  },
                                },
                              }}
                              maxListHeight="350px"
                            />
                          </div>
                          <div>
                            <Button
                              type="submit"
                              variant="solid"
                              isDisabled={
                                isEqual(formValues, previouslySubmittedValues) || !isEmpty(form?.formState?.errors)
                              }
                            >
                              Apply
                            </Button>
                          </div>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </Box>
                </Grid.Col>
              )}

              {isLoading || isSubmitting || countIsLoading || countIsFetching || riskIsLoading || riskIsFetching ? (
                <Loader verticalAlignment="top" />
              ) : (
                <React.Fragment>
                  {!isEmpty(previouslySubmittedValues?.views) && (
                    <Grid css={{ margin: 0, padding: 0, width: '100%' }}>
                      <Grid.Col
                        span={{ xs: '100%' }}
                        css={{ paddingTop: 'var(--abyss-space-lg)', paddingBottom: 'var(--abyss-space-lg)' }}
                      >
                        <Layout.Group space={themeConfiguration?.theme?.space?.lg}>
                          <Layout.Group>
                            <Text fontWeight="bold">Total Records:</Text>
                            <Badge variant="info" outline>
                              {Number(riskRecordsCount || 0).toLocaleString('en-US')}
                            </Badge>
                          </Layout.Group>
                          <div>
                            <Button
                              variant="solid"
                              before={<IconSymbol icon="edit" variant="outline" />}
                              onClick={async (event) => {
                                event?.preventDefault();
                                await setLocalStorage();
                                router?.navigate('/action-paths/create');
                              }}
                            >
                              Draft Action Path
                            </Button>
                          </div>
                        </Layout.Group>
                      </Grid.Col>
                      <Grid.Col
                        span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}
                        css={{ margin: 0, padding: 0 }}
                      >
                        <Flex
                          justify="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          direction="row"
                          css={{ width: '100%' }}
                        >
                          <React.Fragment>
                            {Object.keys(queries).map((queryKey) => {
                              const aggregation = queries?.[queryKey]?.data;
                              const view = assets?.ListViews?.data?.find((theView) => {
                                return theView?.name === aggregation?.researchAggregation?.name;
                              });

                              return (
                                <AggregationView
                                  key={queryKey}
                                  aggregation={aggregation}
                                  view={view}
                                  riskCodes={assets?.ListRiskCodes?.data}
                                />
                              );
                            })}
                          </React.Fragment>
                          {previouslySubmittedValues?.views?.map((viewId) => {
                            if (viewId === 'risk-records') {
                              if (!isUndefined(riskData)) {
                                return (
                                  <AggregationView
                                    key="ListRiskRecords"
                                    aggregation={riskData}
                                    view={{
                                      id: 'risk-records',
                                      name: 'Risk Records Sample Data',
                                      description: 'Sample risk records that match filter criteria.',
                                    }}
                                    requestArgs={{
                                      page: 0,
                                      size: 25,
                                      sort: 'eid,asc',
                                      filters: previouslySubmittedValues?.filters,
                                    }}
                                    requestFunction={ListRiskRecords}
                                    requestKey="ListRiskRecords"
                                    assets={assets}
                                    isLoading={riskIsLoading || riskIsFetching}
                                    error={riskError}
                                  />
                                );
                              }
                            }
                            return null;
                          })}
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  )}
                </React.Fragment>
              )}
            </Grid>
          </FormProvider>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isNull, orderBy } from 'lodash';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Records } from './components/Records';
import { SplitMerge } from './components/SplitMerge';

/**
 * Eimp
 *
 * Displays risk records from EIMP.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Eimp = (props) => {
  const { trustedRecords, untrustedRecords, changeData } = props;

  const rows = orderBy(
    [
      ...trustedRecords.map((trustedRecord) => {
        return { ...trustedRecord, ...{ status: 'trusted' } };
      }),
      ...untrustedRecords.map((untrustedRecord) => {
        return { ...untrustedRecord, ...{ status: 'untrusted' } };
      }),
    ],
    ['status'],
    ['asc']
  );

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Eimp/Eimp.jsx">
      <Visibility>
        <Widget title="EIMP" description="EIMP Merge/Split History and Source data" collapsible collapsed>
          <Grid>
            {isEmpty(rows) ? (
              <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
                <Alert title="There is no EIMP trusted/untrusted data for this risk record." variant="info" />
              </Grid.Col>
            ) : (
              <Grid.Col span={{ xs: '100%' }}>
                <Records rows={rows} />
              </Grid.Col>
            )}
            {!isEmpty(changeData) && !isNull(changeData) && (
              <Grid.Col span={{ xs: '100%' }}>
                <SplitMerge changeData={changeData} />
              </Grid.Col>
            )}
          </Grid>
        </Widget>
      </Visibility>
    </ErrorHandler>
  );
};

Eimp.propTypes = {
  trustedRecords: PropTypes.arrayOf(
    PropTypes.shape({
      eid: PropTypes.string,
      status: PropTypes.string,
      dob: PropTypes,
    })
  ),
  untrustedRecords: PropTypes.arrayOf(
    PropTypes.shape({
      eid: PropTypes.string,
      status: PropTypes.string,
      dob: PropTypes,
    })
  ),
  changeData: PropTypes.shape({
    changeEvents: PropTypes.arrayOf(
      PropTypes.shape({
        changeType: PropTypes.string,
        changeDate: PropTypes.string,
        changeDescription: PropTypes.string,
        associatedSplitEids: PropTypes.arrayOf(PropTypes.string),
        associatedMergedEids: PropTypes.arrayOf(PropTypes.string),
        mergedInto: PropTypes.string,
      })
    ),
  }),
};

Eimp.defaultProps = {
  trustedRecords: [],
  untrustedRecords: [],
  changeData: { changeEvents: [] },
};

import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as AbyssTable } from '@abyss/web/ui/Table';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Visibility } from '@src/components/Visibility';
import PropTypes from 'prop-types';

/**
 * Table
 *
 * Table component to display the alert information
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { alert } = props;

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Alerts/List/components/EditModal/components/Table/Table.jsx">
      <Visibility>
        <AbyssTable.Container title="" style={{ width: '752px', tableLayout: 'fixed' }}>
          <AbyssTable.TableHeader>
            <AbyssTable.TableHeaderRow>
              <AbyssTable.Column style={{ width: '25%' }}>Last Updated</AbyssTable.Column>
              <AbyssTable.Column>Description</AbyssTable.Column>
            </AbyssTable.TableHeaderRow>
          </AbyssTable.TableHeader>
          <AbyssTable.TableBody>
            <AbyssTable.Row>
              <AbyssTable.Cell>{dayjs(alert?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
              <AbyssTable.Cell>
                <Tooltip
                  content={
                    <div
                      style={{
                        padding: themeConfiguration?.theme?.space?.sm,
                        fontFamily: themeConfiguration?.theme?.fonts?.primary,
                      }}
                    >
                      {alert?.shortDesc}
                    </div>
                  }
                >
                  <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {alert?.shortDesc}
                  </div>
                </Tooltip>
              </AbyssTable.Cell>
            </AbyssTable.Row>
          </AbyssTable.TableBody>
        </AbyssTable.Container>
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  alert: PropTypes.shape({
    lastModifiedDate: PropTypes.string,
    shortDesc: PropTypes.string,
  }),
};

Table.defaultProps = {
  alert: {},
};

import React from 'react';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Divider } from '@abyss/web/ui/Divider';
import { Visibility } from '@src/components/Visibility';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { AnalyzeRisksButton } from '@src/components/AnalyzeRisksButton';
import PropTypes from 'prop-types';
import { ExportSharePoint } from './components/ExportSharePoint';
import { ViewSharePoint } from './components/ViewSharePoint';

/**
 * Header
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const { allowExport, exportDetails, exportRefetch, form, pollingRate, riskRecordCount, setPollingRate } = props;

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/Header/Header.jsx">
      <Visibility>
        <Layout.Group css={{ height: '100%' }} alignItems="bottom" space={themeConfiguration?.theme?.space?.sm}>
          {allowExport === true && (
            <div>
              <ExportSharePoint
                form={form}
                exportDetails={exportDetails}
                setPollingRate={setPollingRate}
                pollingRate={pollingRate}
                riskRecordCount={riskRecordCount}
                exportRefetch={exportRefetch}
              />
            </div>
          )}
          <div>
            <Divider orientation="vertical" width={1} height={24} />
          </div>
          {allowExport === true && (
            <div>
              <ViewSharePoint form={form} exportDetails={exportDetails} />
            </div>
          )}
          <div>
            <Divider orientation="vertical" width={1} height={24} />
          </div>
          <div>
            <AnalyzeRisksButton filters={form?.getValues('filters')} variant="link" />
          </div>
        </Layout.Group>
      </Visibility>
    </ErrorHandler>
  );
};

Header.propTypes = {
  allowExport: PropTypes.bool,
  exportDetails: PropTypes.shape({
    exportId: PropTypes.string,
    exportStatus: PropTypes.string,
    exportUrl: PropTypes.string,
  }),
  exportRefetch: PropTypes.func,
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  pollingRate: PropTypes.number,
  riskRecordCount: PropTypes.number,
  setPollingRate: PropTypes.func,
};

Header.defaultProps = {
  allowExport: false,
  exportDetails: {},
  exportRefetch: () => {},
  form: {},
  pollingRate: 0,
  riskRecordCount: 0,
  setPollingRate: () => {},
};

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';

/**
 * AssignTags
 *
 * Displays a modal to assign action paths to the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AssignTags = (props) => {
  const { eid, isOpen, setIsOpen, refetchRiskRecord, recordTags } = props;

  const { useApiQuery, useApiMutation, clearApiCache } = useApi();

  const [ListTags, { data: tags, isLoading, isFetching }] = useApiQuery('ListTags');
  const [RiskRecordTagAssignment] = useApiMutation('RiskRecordTagAssignment', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    tags: [],
  };

  const form = useForm({ defaultValues });
  const { toast } = useToast();

  /**
   * Retrieve the list of action paths.
   */
  useEffect(() => {
    if (isUndefined(tags)) {
      ListTags({
        page: 0,
        size: 9999,
        sort: 'code,asc',
        userDefined: true,
      });
    }
  }, [tags]);

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    try {
      const toastId = `tag-assignment-${eid}`;
      toast.show({
        id: `${toastId}-info`,
        title: 'Assigning Tags to EID',
        message: 'Assigning Tags to EID...',
        isLoading: true,
        ariaLoadingLabel: 'Assigning Tags to EID',
        variant: 'info',
        autoClose: false,
      });

      const requests = submittedValues?.tags?.map(async (tag) => {
        const payload = {
          eid,
          tagCode: tag,
        };

        // eslint-disable-next-line @typescript-eslint/return-await
        return await RiskRecordTagAssignment(payload, {
          onSuccess: () => {
            setIsOpen(false);
            clearApiCache(['GetRiskRecord']);
            refetchRiskRecord();
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-success`,
              title: 'Assigned Tags to EID',
              message: 'Acton Paths have been successfully assigned.',
              variant: 'success',
            });
            form?.setValue('tags', []);
          },
          onError: () => {
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-error`,
              title: 'Assigning Tags Failed',
              message: 'Unable to assign Tags.',
              variant: 'error',
            });
            setIsOpen(false);
            form?.setValue('tags', []);
          },
        });
      });

      await Promise.all(requests);
    } catch (error) {
      console.error('Error in handleSubmit', error);
    }
  };

  /**
   * tagOptions
   *
   * Returns the tag options with categories for sections.
   *
   * @type {*[]}
   */
  const tagOptions = useMemo(() => {
    const theTags = [];

    if (!isUndefined(tags) && !isEmpty(tags)) {
      const availableTags = tags?.content?.filter((tag) => {
        return !recordTags?.find((recordTag) => {
          return recordTag === tag?.tag;
        });
      });

      // tag category sections
      availableTags?.forEach((tag) => {
        theTags.push({
          section: tag?.categoryDesc,
          items: [],
        });
      });

      // tag options within sections
      availableTags?.forEach((tag) => {
        theTags.forEach((tagSection) => {
          if (tagSection?.section === tag?.categoryDesc || tagSection?.section === tag?.categoryCode) {
            tagSection?.items.push({
              label: `${tag?.code} (${tag?.categoryCode})`,
              value: tag?.tag,
            });
          }
        });
      });
    }

    return theTags;
  }, [tags]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/components/AssignTags/AssignTags.jsx">
      <Visibility>
        <Modal
          title="Assign Tags"
          isOpen={isOpen}
          onClose={() => {
            return setIsOpen(false);
          }}
        >
          <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
            <Modal.Section>
              <Grid>
                {isLoading || isFetching || isUndefined(tags) ? (
                  <Grid.Col
                    span={{
                      xs: '100%',
                    }}
                  >
                    <Loader />
                  </Grid.Col>
                ) : (
                  <Grid.Col
                    span={{
                      xs: '50%',
                    }}
                  >
                    <SelectInputMulti
                      label="Tags"
                      placeholder="Select Tags --"
                      model="tags"
                      isSearchable
                      options={tagOptions}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </Modal.Section>
            <Modal.Section>
              <Divider height={1} />
              <Layout.Group alignLayout="right">
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="solid" type="submit" isDisabled={!form?.formState?.isDirty}>
                  Assign
                </Button>
              </Layout.Group>
            </Modal.Section>
          </FormProvider>
        </Modal>
      </Visibility>
    </ErrorHandler>
  );
};

AssignTags.propTypes = {
  eid: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetchRiskRecord: PropTypes.func,
  recordTags: PropTypes.arrayOf(PropTypes.string),
};

AssignTags.defaultProps = {
  eid: '',
  isOpen: false,
  setIsOpen: () => {},
  refetchRiskRecord: () => {},
  recordTags: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { useApi } from '@src/context/Api';
import { useToast } from '@abyss/web/hooks/useToast';
import { Styles } from './includes/styles';

/**
 * RemoveModal
 *
 * Displays a modal popup to remove EID's from the list of associated EID's with the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemoveModal = (props) => {
  const { actionPath, eids, isOpen, refetch, setEids, setIsOpen } = props;

  const { toast } = useToast();

  const { useApiMutation, clearApiCache } = useApi();
  const [RiskRecordActionPathDisassociation] = useApiMutation('RiskRecordActionPathDisassociation');

  /**
   * handleClose
   *
   * Closes the modal popup and resets the EID.
   */
  const handleClose = () => {
    setEids([]);
    setIsOpen(false);
  };

  /**
   * handleDisassociation
   *
   * Sends an api request to disassociate the EID's from the action path.
   *
   * @returns {Promise<void>}
   */
  const handleDisassociation = async () => {
    try {
      const toastId = 'eid-disassociation';
      toast.show({
        id: `${toastId}-info`,
        title: "Disassociating EID's From Action Path...",
        message: "Action Path is preparing to disassociate the EID's.",
        isLoading: true,
        ariaLoadingLabel: "Disassociating EID's From Action Path",
        variant: 'info',
        autoClose: false,
      });

      handleClose();

      const payload = {
        actionPaths: [actionPath?.id],
        eids,
      };

      await RiskRecordActionPathDisassociation(payload, {
        onSuccess: async () => {
          await clearApiCache(['ListRiskRecords']);
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: "Disassociated EID's From Action Path",
            message: "EID's have been disassociated.",
            variant: 'success',
          });

          setTimeout(() => {
            refetch();
          }, 750);
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: "Disassociating EID's Failed",
            message: "Unable to disassociate EID's.",
            variant: 'error',
          });
        },
      });
    } catch (error) {
      console.error('Error in handleDisassociation', error);
    }
  };

  return (
    <ErrorHandler location="src/widgets/AssociatedEids/components/RemoveModal/RemoveModal.jsx">
      <Modal
        title="Confirm Association Removal"
        isOpen={isOpen}
        onClose={handleClose}
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDisassociation}>
                Remove Association
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
      >
        <Styles>
          <Modal.Section>
            {eids?.length > 1 ? (
              <React.Fragment>
                Are you sure you want to remove the following associated EID's: <strong>{eids?.join(', ')}</strong> ?
              </React.Fragment>
            ) : (
              <React.Fragment>
                Are you sure you want to remove associated EID: <strong>{eids?.[0]}</strong> ?
              </React.Fragment>
            )}
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

RemoveModal.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  eids: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setEids: PropTypes.func,
  setIsOpen: PropTypes.func,
};

RemoveModal.defaultProps = {
  actionPath: {},
  eids: [],
  isOpen: false,
  refetch: () => {},
  setEids: () => {},
  setIsOpen: () => {},
};

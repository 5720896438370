import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useApi } from '@src/context/Api';
import { useUserContext } from '@src/context/User';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';
import { RemoveModal } from './components/RemoveModal';
import { AddModal } from './components/AddModal';

/**
 * Widget: AssociatedEids
 *
 * Allows adding/removing EID's to/from an active/manual action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AssociatedEids = (props) => {
  const { actionPath } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [eids, setEids] = useState([]);

  const { roles } = useUserContext();

  const { useApiQuery } = useApi();

  const [ListRiskRecords, { data: riskRecords, isLoading, isFetching, error, refetch }] =
    useApiQuery('ListRiskRecords');

  return (
    <ErrorHandler location="src/widgets/AssociatedEids/AssociatedEids.jsx">
      <Visibility>
        <LoadingOverlay
          loadingTitle="Loading..."
          loadingMessage="Your request is being processed."
          ariaLoadingLabel="Your request is being processed."
          isLoading={isLoading || isFetching}
        >
          <Widget
            title="Assosciated EID's"
            description="Other Entities that this record is connected to"
            button={
              roles.includes('State.Write') && (
                <Button
                  before={<IconSymbol icon="add" />}
                  css={{
                    marginLeft: themeConfiguration?.theme?.space?.md,
                  }}
                  size="$sm"
                  variant="outline"
                  onClick={() => {
                    return setShowAddModal(true);
                  }}
                >
                  Add EID
                </Button>
              )
            }
          >
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              refetch={refetch}
              requestArgs={{
                page: 0,
                size: 5,
                sort: 'eid,asc',
                filters: [
                  {
                    field: 'ACTION_PATH_ID',
                    condition: 'EQ',
                    value: actionPath?.id,
                  },
                ],
              }}
              requestFunction={ListRiskRecords}
              requestKey="ListRiskRecords"
              rows={riskRecords?.content || []}
              setEids={setEids}
              setIsOpen={setShowRemoveModal}
              totalPages={riskRecords?.totalPages || 1}
              totalRecords={riskRecords?.totalElements || 0}
            />
          </Widget>
        </LoadingOverlay>
        {roles.includes('State.Write') && (
          <React.Fragment>
            <AddModal actionPath={actionPath} isOpen={showAddModal} refetch={refetch} setIsOpen={setShowAddModal} />
            <RemoveModal
              actionPath={actionPath}
              eids={eids}
              isOpen={showRemoveModal}
              refetch={refetch}
              setEids={setEids}
              setIsOpen={setShowRemoveModal}
            />
          </React.Fragment>
        )}
      </Visibility>
    </ErrorHandler>
  );
};

AssociatedEids.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AssociatedEids.defaultProps = {
  actionPath: {},
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { abbrNum } from '@src/includes/functions';
import { Button } from '@src/components/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNull, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Styles } from './includes/styles';

/**
 * ActivationModal
 *
 * Prompts the user with a popup window asking them if they wish to cancel or continue activating the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActivationModal = (props) => {
  const { isOpen, setIsOpen, actionPath, setActionPath, handleActivation } = props;

  const [isChecked, setChecked] = useState(false);

  const router = useRouter();

  const { useApiQuery } = useApi();
  const [GetActionPathCount, { data: riskRecordCount, isLoading, isFetching, isFetched }] =
    useApiQuery('GetActionPathCount');

  useEffect(() => {
    if (!isFetched && isUndefined(riskRecordCount)) {
      GetActionPathCount({ actionPathId: actionPath?.id });
    }
  }, [isFetched, riskRecordCount]);

  /**
   * handleSave
   *
   * @TODO Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleSave = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      await handleActivation(actionPath);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx -> handleSave() -> error:',
        theError
      );

      throw error;
    }
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      router?.navigate(`/`);
      router?.navigate(`/action-paths?highlight=${actionPath?.id}`);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx -> handleClose() -> error:',
        theError
      );

      throw error;
    }
  };
  let count = riskRecordCount;

  if (riskRecordCount > 0) {
    count = abbrNum(riskRecordCount);
  }

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx">
      <Modal
        title="Confirm Activation"
        isOpen={isOpen}
        onClose={handleClose}
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={handleSave}
                isDisabled={isLoading || isFetching || isNull(riskRecordCount) || (riskRecordCount === 0 && !isChecked)}
              >
                Activate
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
      >
        <Styles>
          <Modal.Section>
            <Layout.Group>
              <div>The number of records associated with this Action Path is:</div>
              <div>
                <strong>
                  {isLoading || isFetching || isNull(count) ? (
                    <LoadingSpinner size="$sm" isLoading ariaLoadingLabel="Loading..." />
                  ) : (
                    <React.Fragment>{count}</React.Fragment>
                  )}
                </strong>
              </div>
            </Layout.Group>

            {count === 0 && (
              <Checkbox
                label="Continue with 0 records"
                isChecked={isChecked}
                onChange={(event) => {
                  return setChecked(event?.target?.checked);
                }}
              />
            )}
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

ActivationModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  actionPath: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  setActionPath: PropTypes.func,
  handleActivation: PropTypes.func,
};

ActivationModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  actionPath: {},
  setActionPath: () => {},
  handleActivation: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { Table } from './components/Table';

/**
 * RepeatableFields
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RepeatableFields = (props) => {
  const { accessor, disabledFields, fields, form, placeholderValues } = props;

  const {
    fields: repeatableFields,
    append,
    remove,
  } = useFormFieldArray({
    control: form.control,
    name: `repeatableFields.${accessor}`,
  });

  return (
    <ErrorHandler location="src/components/RepeatableFields/RepeatableFields.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col span={{ xs: '100%' }}>
              <Layout.Group alignLayout="right">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  variant="custom"
                  id="addRow"
                  onClick={() => {
                    append(placeholderValues);
                  }}
                >
                  <Flex alignItems="center">
                    <div>
                      <IconSymbol icon="add_circle" variant="filled" />
                      <IconSymbol icon="add_circle" variant="outline" />
                    </div>
                    <div>Add Row</div>
                  </Flex>
                </Link>
              </Layout.Group>
              <Table
                {...{
                  accessor,
                  disabledFields,
                  fields,
                  remove,
                  repeatableFields,
                }}
              />
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

RepeatableFields.propTypes = {
  accessor: PropTypes.string.isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    setValue: PropTypes.func,
  }).isRequired,
  placeholderValues: PropTypes.shape(PropTypes.shape({})).isRequired,
};

RepeatableFields.defaultProps = {
  disabledFields: [],
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Assignments, Trusted, Untrusted } from '@src/tables';
import { config } from '@abyss/web/tools/config';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isUndefined } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { useApi } from '@src/context/Api';
import { Visibility } from '@src/components/Visibility';
import { Skeleton } from './components/Skeleton';

/**
 * ExpansionRow
 *
 * Expands a table row on click.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ExpansionRow = (props) => {
  const { row } = props;

  const { useApiQuery } = useApi();

  const [GetRiskRecord, { data, isLoading, isFetching }] = useApiQuery('GetRiskRecord');

  /**
   * Fetches additional data about the risk record when the row is expanded/opened.
   */
  useEffect(() => {
    if (isUndefined(data)) {
      GetRiskRecord({ eid: row?.eid });
    }
  }, [row]);

  if (isLoading || isFetching) {
    return <Skeleton />;
  }

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/ExpansionRow/ExpansionRow.jsx">
      <Visibility>
        <Grid css={{ margin: 0 }}>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Heading offset={2}>Record View</Heading>
            <p>
              <strong>EID:</strong>{' '}
              <Link openNewWindow href={`${config('HCM_URL')}/${row?.eid}/`}>
                {row?.eid}
              </Link>
            </p>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <Assignments row={row} record={data} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '25%',
            }}
          >
            <Trusted row={row} record={data} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '25%',
            }}
          >
            <Untrusted row={row} record={data} />
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

ExpansionRow.propTypes = {
  row: PropTypes.shape({
    eid: PropTypes.string,
  }),
};

ExpansionRow.defaultProps = {
  row: {},
};
